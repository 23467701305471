/* eslint-disable no-restricted-properties */
/* eslint-disable no-param-reassign */
import { map, range, always, pipe, sum } from "ramda";
import { renameKeys } from "ramda-adjunct";

import { REPAYMENT_FREQUENCY } from "./constants";

const { WEEKLY, MONTHLY, FORTNIGHTLY, ANNUAL } = REPAYMENT_FREQUENCY;

function rangeStep(step, from, to) {
  const callback = step === 0 ? always(from) : (n) => from + step * n;

  const rangeEnd = step === 0 ? to - from : Math.floor((to - from) / step);

  return map(callback, range(0, rangeEnd));
}

function calculatePeriods(loanTerm, type = MONTHLY) {
  switch (type) {
    case WEEKLY:
      return loanTerm * 4 * 12;
    case FORTNIGHTLY:
      return loanTerm * 2 * 12;
    default:
      return loanTerm * 12;
  }
}

function calculateRate(annualRatePercent, type = MONTHLY) {
  switch (type) {
    case WEEKLY:
      return annualRatePercent / (12 * 4 * 100);
    case FORTNIGHTLY:
      return annualRatePercent / (12 * 2 * 100);
    default:
      return annualRatePercent / (12 * 100);
  }
}

function calculateIncomeByFrequency(annualIncome, type = MONTHLY) {
  switch (type) {
    case WEEKLY:
      return annualIncome / (12 * 4);
    case FORTNIGHTLY:
      return annualIncome / (12 * 2);
    default:
      return annualIncome / 12;
  }
}

function calculateIndividualIncome({ primary, secondary, other }) {
  return pipe(map(convertToAnnualPayment), sum)([primary, secondary, other]);
}

function calculateTotalIncome(salaries = []) {
  return pipe(map(calculateIndividualIncome), sum)(salaries);
}

function convertToAnnualPayment({ amount = 0, frequency = ANNUAL }) {
  switch (frequency) {
    case WEEKLY:
      return amount * 52;
    case FORTNIGHTLY:
      return amount * 26;
    case MONTHLY:
      return amount * 12;
    default:
      return amount;
  }
}

function calculateTotalExpense(
  numOfDependants = 0,
  annualExpenses = 0,
  creditCardLimit = { amount: 0, frequency: ANNUAL },
  carLoanRepayment = { amount: 0, frequency: ANNUAL },
  otherPayment = { amount: 0, frequency: ANNUAL }
) {
  const dependantExpense = numOfDependants * 4000;
  const creditCardExpenses = convertToAnnualPayment(creditCardLimit) * 0.03;
  const annualCarPmt = convertToAnnualPayment(carLoanRepayment);
  const otherAnnualPmt = convertToAnnualPayment(otherPayment);

  return (
    annualExpenses +
    dependantExpense +
    annualCarPmt +
    otherAnnualPmt +
    creditCardExpenses
  );
}
function calculateEligibleIncomeForDebtServicing(
  annualIncome,
  annualExpenses,
  netServicingRatio = 0.7
) {
  return (annualIncome - annualExpenses) * netServicingRatio;
}
// https://www.experts-exchange.com/articles/1948/A-Guide-to-the-PMT-FV-IPMT-and-PPMT-Functions.html
const Excel = {
  pmt(r, nper, pv, fv = 0, type = 0) {
    // pmt = r / ((1 + r)^N - 1) * -(pv * (1 + r)^N + fv)
    let pmt =
      (r / (Math.pow(1 + r, nper) - 1)) * -(pv * Math.pow(1 + r, nper) + fv);

    // account for payments at beginning of period versus end.
    if (type === 1) pmt /= 1 + r;

    return pmt;
  },

  fv(r, nper, c, pv, type) {
    // account for payments at beginning of period versus end.
    // since we are going in reverse, we multiply by 1 plus interest rate.
    if (type === 1) c *= 1 + r;

    // fv = -(((1 + r)^N - 1) / r * c + pv * (1 + r)^N);
    const fv = -(
      ((Math.pow(1 + r, nper) - 1) / r) * c +
      pv * Math.pow(1 + r, nper)
    );

    return fv;
  },

  ipmt(r, per, nper, pv, fv = 0, type = 0) {
    // Prior period (i.e., per-1) balance times periodic interest rate.
    // i.e., ipmt = fv(r, per-1, c, pv, type) * r
    // where c = pmt(r, nper, pv, fv, type)
    let ipmt =
      this.fv(r, per - 1, this.pmt(r, nper, pv, fv, type), pv, type) * r;

    // account for payments at beginning of period versus end.
    if (type === 1) ipmt /= 1 + r;

    return ipmt;
  },

  ppmt(r, per, nper, pv, fv, type) {
    return (
      this.pmt(r, nper, pv, fv, type) - this.ipmt(r, per, nper, pv, fv, type)
    );
  },

  totalPayableInterest(r, nper, pv, fv = 0, type = 0) {
    return -this.pmt(r, nper, pv, fv, type) * nper - pv;
  },

  totalPayablePrincipal(r, nper, pv, fv = 0, type = 0) {
    return -this.pmt(r, nper, pv, fv, type) * nper - pv;
  },

  interestOnlyPmt(r, pv) {
    return r * pv;
  },

  consecutivePeriods(rate, endPeriod, nper, initialPv) {
    const periodRange = range(1, endPeriod + 1);
    const res = [
      { payment: 0, period: 0, interest: 0, principal: 0, equity: initialPv },
    ];

    const payment = this.pmt(rate, nper, initialPv);

    let equity = initialPv;
    periodRange.forEach((period) => {
      const principal = this.ppmt(rate, period, nper, initialPv);

      equity += principal;

      res.push({
        period,
        interest: payment - principal,
        principal,
        equity,
        payment,
      });
    });

    return res;
  },

  consecutivePeriodsInterestOnly(rate, endPeriod, initialPv) {
    const periodRange = range(1, endPeriod + 1);
    const res = [
      { payment: 0, period: 0, interest: 0, principal: 0, equity: initialPv },
    ];

    const payment = this.interestOnlyPmt(rate, initialPv);

    periodRange.forEach((period) => {
      res.push({
        period,
        payment,
        interest: payment,
        principal: 0,
        equity: initialPv,
      });
    });

    return res;
  },

  totalPayableInterestForInterestOnly(rate, nper, initialPv) {
    return nper * this.interestOnlyPmt(rate, initialPv);
  },

  pv(
    annualIncome,
    annualRatePercent = 6.45,
    loanTermInYears = 30,
    frequency = MONTHLY
  ) {
    const rate = calculateRate(annualRatePercent, frequency);
    const nper = calculatePeriods(loanTermInYears, frequency);
    const income = calculateIncomeByFrequency(annualIncome, frequency);

    return (income / rate) * (1 - Math.pow(1 + rate, -nper));
  },
};

// eslint-disable-next-line complexity
function formatCurrency(value, digits = 2) {
  if (Number.isNaN(value)) return "$-";
  if (Math.abs(value) === 0 || (value < 0 && value > -1)) return "$0";
  if (Math.round(value) === value) return `$${value}`;

  return new Intl.NumberFormat("en-NZ", {
    style: "currency",
    currency: "NZD",
    maximumFractionDigits: digits,
  }).format(value);
}

function formatPercent(val = "") {
  return `${val}%`;
}
function capitalize(w = "") {
  return w.charAt(0).toUpperCase() + w.slice(1);
}
function tryParseFloat(str) {
  const res = parseFloat(str);
  return Number.isNaN(res) ? 0 : res;
}
function ensureAccountAmountIsNumber({ amount, ...props }) {
  return { amount: tryParseFloat(amount), ...props };
}

function makeSliderProps({ STEP, MAX, MIN }) {
  return {
    max: MAX.toString(),
    min: MIN.toString(),
    step: STEP.toString(),
  };
}

const mapRepaymentCalculatorSummaryData = (
  values,
  payments,
  totalInterest,
  repaymentValue
) => {
  const {
    interestRate,
    loanAmount,
    loanTerm,
    repaymentFrequency,
    repaymentType,
  } = values;

  const mappedPayments = map((payment) =>
    renameKeys({ equity: "loanAmount" })(payment)
  )(payments);

  const mappedData = {
    interestRate,
    loanAmount,
    loanTerm,
    repaymentFrequency,
    repayments: mappedPayments,
    repaymentType,
    repaymentValue,
    totalInterest,
  };

  return mappedData;
};

const mapBorrowingCalculatorSummaryData = (values, maxLoanAmount, pmt) => {
  const {
    clientIncome,
    hasPartner,
    partnerIncome,
    dependants,
    annualExpenses,
    creditCardLimit,
    carLoanRepayment,
    otherPayment,
    loanTerm,
  } = values;

  const mappedData = {
    clientIncome,
    hasPartner,
    partnerIncome,
    dependants,
    annualExpenses,
    creditCardLimit,
    carLoanRepayment,
    otherPayment,
    loanTerm,
    maxLoanAmount,
    pmt,
  };

  return mappedData;
};

const regions = {
  akaroa: "Akaroa",
  arrowtown: "Arrowtown",
  ashburton: "Ashburton",
  aucklandCentral: "Auckland Central",
  aucklandCityLiving: "Auckland City Living",
  aucklandNorth: "Auckland North",
  aucklandSouth: "Auckland South",
  bayOfIslands: "Bay of Islands",
  bayOfPlenty: "Bay of Plenty",
  bayleyCorporation: "Bayley Corporation",
  bayleysRealtyGroup: "Bayleys Realty Group",
  botany: "Botany",
  breamBay: "Bream Bay",
  cambridge: "Cambridge",
  centralNorthIsland: "Central North Island",
  christchurch: "Christchurch",
  coatesville: "Coatesville",
  cromwell: "Cromwell",
  darfield: "Darfield",
  dargaville: "Dargaville",
  devonport: "Devonport",
  dunedinHighgate: "Dunedin Highgate",
  dunedinMosgiel: "Dunedin Mosgiel",
  ellerslie: "Ellerslie",
  feidling: "Feidling",
  fendalton: "Fendalton",
  ferrymead: "Ferrymead",
  fiji: "Fiji",
  frankton: "Frankton",
  gisborne: "Gisborne",
  gore: "Gore",
  hamilton: "Hamilton",
  hanmerSprings: "Hanmer Springs",
  havelockNorth: "Havelock North",
  hawera: "Hawera",
  howick: "Howick",
  kaikoura: "Kaikoura",
  kapiti: "Kapiti",
  kapitiBRE: "Kapiti BRE",
  kumeu: "Kumeu",
  longBay: "Long Bay",
  lowerNorthIsland: "Lower North Island",
  lowerSouthIsland: "Lower South Island",
  mairangiBay: "Mairangi Bay",
  mangawhai: "Mangawhai",
  marlborough: "Marlborough",
  matakana: "Matakana",
  matamata: "Matamata",
  merivale: "Merivale",
  methven: "Methven",
  middleSouthIsland: "Middle South Island",
  millwater: "Millwater",
  motueka: "Motueka",
  mtMaunganui: "Mt Maunganui",
  napier: "Napier",
  nelson: "Nelson",
  northland: "Northland",
  northShoreCommercial: "North Shore Commercial",
  northwest: "Northwest",
  omahaBeach: "Omaha Beach",
  oneTreeHill: "One Tree Hill",
  orewa: "Orewa",
  palmerstonNorth: "Palmerston North",
  papakura: "Papakura",
  ponsonby: "Ponsonby",
  prestons: "Prestons",
  propertyServicesAuckland: "Property Services - Auckland",
  propertyServicesChristchurch: "Property Services - Christchurch",
  propertyServicesHamilton: "Property Services - Hamilton",
  propertyServicesTauranga: "Property Services - Tauranga",
  propertyServicesWellington: "Property Services - Wellington",
  pukekohe: "Pukekohe",
  queenstown: "Queenstown",
  rangiora: "Rangiora",
  remuera: "Remuera",
  richmond: "Richmond",
  rollston: "Rollston",
  rotorua: "Rotorua",
  ruapehu: "Ruapehu",
  southAuckland: "South Auckland",
  southland: "Southland",
  southlandRural: "Southland Rural",
  stHeliers: "St Heliers",
  takapunaResidential: "Takapuna Residential",
  taranaki: "Taranaki",
  taupo: "Taupo",
  tauranga: "Tauranga",
  thames: "Thames",
  timaru: "Timaru",
  turangi: "Turangi",
  upperHutt: "Upper Hutt",
  upperSouthIsland: "Upper South Island",
  valuationsAuckland: "Valuations - Auckland",
  valuationsChristchurch: "Valuations - Christchurch",
  valuationsHamilton: "Valuations - Hamilton",
  valuationsWellington: "Valuations - Wellington",
  waihekeAndGreatBarrier: "Waiheke and Great Barrier",
  waipukurau: "Waipukurau",
  wairarapa: "Wairarapa",
  wanaka: "Wanaka",
  warkworth: "Warkworth",
  wellingtonCAndI: "Wellington C & I",
  wellingtonResidential: "Wellington Residential",
  wellingtonResidentialBre: "Wellington Residential Bre",
  whakatane: "Whakatane",
  whanganui: "Whanganui",
  whangarei: "Whangarei",
  whitianga: "Whitianga",
};

const mapCustomerInfo = (customerInfo) => {
  const { region } = customerInfo;
  const formattedRegion = regions[region];

  const mappedCustomerInfo = { ...customerInfo, region: formattedRegion };

  return mappedCustomerInfo;
};

export {
  rangeStep,
  Excel,
  formatCurrency,
  formatPercent,
  capitalize,
  calculatePeriods,
  calculateRate,
  calculateTotalIncome,
  calculateTotalExpense,
  calculateEligibleIncomeForDebtServicing,
  tryParseFloat,
  ensureAccountAmountIsNumber,
  makeSliderProps,
  mapRepaymentCalculatorSummaryData,
  mapBorrowingCalculatorSummaryData,
  mapCustomerInfo,
};
