import { useState } from 'react'

export const useEmailModal = () => {
  const [isModalOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  return { isModalOpen, openModal, closeModal }
}
