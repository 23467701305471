import BayleysBorrowingPowerForm from './bayleys/borrowingPower'
import BayleysLoanRepaymentForm from './bayleys/loanRepayment'
import NeoBorrowingPowerForm from './neo/borrowingPower'
import NeoLoanRepaymentForm from './neo/loanRepayment'
import { s, styled, smaller } from 'styled'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Modal from 'react-modal'
import { useLayoutEffect } from 'react'

Modal.setAppElement('#root')

const Container = styled.div(
  s('h-full relative flex flex-column bg-gray-100 px-12 pt-16 pb-16 m-a', {
    height: '100vh',
    overflow: 'auto',
    borderRadius: 4,
  }),
  smaller('sm')(s('p-0 m-0'))
)

function App() {
  useLayoutEffect(() => {
    function loadHubSpot() {
      const isBayleysPage = window.location.pathname.includes('vega') === false
      const scriptId = 'bayleys-analytics-script'
      // only available in staging
      const scriptSrc = process.env.REACT_APP_BAYLEYS_HUBSPOT_SCRIPT
      const existing = document.getElementById(scriptId)
      if (!existing && isBayleysPage && scriptSrc) {
        const script = document.createElement('script')
        script.src = scriptSrc
        script.async = true
        script.id = scriptId
        document.getElementsByTagName('head')[0].appendChild(script)
      }
    }

    loadHubSpot()
  }, [window.location.pathname])

  return (
    <Router>
      <Container>
        <Switch>
          <Route exact path="/loan-repayments" component={BayleysLoanRepaymentForm} />
          <Route exact path="/borrowing-power" component={BayleysBorrowingPowerForm} />
          <Route exact path="/vega-repayments" component={NeoLoanRepaymentForm} />
          <Route exact path="/vega-borrowing" component={NeoBorrowingPowerForm} />
          <Route path="/" component={BayleysLoanRepaymentForm} />
        </Switch>
      </Container>
    </Router>
  )
}

export default App
