import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

import { values } from "ramda";

import { REPAYMENT_FREQUENCY, LOAN_TERM_CONFIG } from "../../constants";
import { rangeStep } from "utils";

import View from "./View";

const { ANNUAL, MONTHLY } = REPAYMENT_FREQUENCY;

const { object, string, number, bool } = Yup;

const loanTermErrorMessage = `Loan Term must be between ${LOAN_TERM_CONFIG.MIN} and ${LOAN_TERM_CONFIG.MAX} years`;

const AccountSchema = (fieldName, required = false) => {
  return required
    ? object({
        amount: number()
          .required()
          .min(0, `${fieldName} must be 0 or more`)
          .label(fieldName)
          .typeError(`${fieldName} must be a number`),
        frequency: string().oneOf(values(REPAYMENT_FREQUENCY)),
      })
    : object({
        amount: number()
          .min(0, `${fieldName} must be 0 or more`)
          .label(fieldName)
          .typeError(`${fieldName} must be a number`),
        frequency: string().oneOf(values(REPAYMENT_FREQUENCY)),
      });
};

export const validationSchema = object({
  clientIncome: object({
    primary: AccountSchema("Primary Salary", true),
    secondary: AccountSchema("Secondary Salary"),
    other: AccountSchema("Other Salary"),
  }).required(),
  hasPartner: bool().optional(),
  partnerIncome: object({
    primary: AccountSchema("Primary Salary"),
    secondary: AccountSchema("Secondary Salary"),
    other: AccountSchema("Other Salary"),
  }).optional(),
  dependants: number().label("Number of Dependants").oneOf([0, 1, 2, 3, 4, 6]),
  annualExpenses: number()
    .min(0)
    .label("Annual Expenses")
    .typeError("Annual Expenses must be a number"),
  creditCardLimit: AccountSchema("Credit Card Limit"),
  carLoanRepayment: AccountSchema("Car Loan Repayment"),
  otherPayment: AccountSchema("Other Payment"),
  loanTerm: number()
    .label("Loan term")
    .oneOf(
      rangeStep(
        LOAN_TERM_CONFIG.STEP,
        LOAN_TERM_CONFIG.MIN,
        LOAN_TERM_CONFIG.MAX + 1
      ),
      loanTermErrorMessage
    )
    .typeError("Loan Term must be a number"),
});

const BorrowingPowerForm = () => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        clientIncome: {
          primary: { amount: 80000, frequency: ANNUAL },
          secondary: { amount: 32000, frequency: ANNUAL },
          other: { amount: 0, frequency: ANNUAL },
        },
        hasPartner: false,
        partnerIncome: {
          primary: { amount: 0, frequency: ANNUAL },
          secondary: { amount: 0, frequency: ANNUAL },
          other: { amount: 0, frequency: ANNUAL },
        },
        dependants: 2,
        annualExpenses: 28000,
        creditCardLimit: { amount: 1000, frequency: MONTHLY },
        carLoanRepayment: { amount: 100, frequency: ANNUAL },
        otherPayment: { amount: 0, frequency: ANNUAL },
        loanTerm: 30,
      }}
    >
      <View />
    </Formik>
  );
};

export default BorrowingPowerForm;
