import React from 'react'

import { TableBase } from './TableBase'
import { useTable } from 'react-table'
import debounceRender from 'react-debounce-render'

function Table({
  columns,
  data,
  onClick,
  tBodyStyle,
  paginationProps,
  loading,
  ...props
}) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    }
  )

  return (
    <TableBase
      tBodyStyle={tBodyStyle}
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      prepareRow={prepareRow}
      page={rows}
      onClick={onClick}
      {...props}
    />
  )
}

export default debounceRender(Table, 1000)
