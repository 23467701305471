import * as React from 'react'

export const ListIcon = ({ width = 32, height = 32, ...props }) => {
  return (
    <svg
      className="prefix__icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <path d="M146.286 749.714V859.43q0 7.46-5.413 12.873T128 877.714H18.286q-7.46 0-12.873-5.412T0 859.429V749.714q0-7.46 5.413-12.873t12.873-5.412H128q7.46 0 12.873 5.412t5.413 12.873zm0-219.428V640q0 7.46-5.413 12.873T128 658.286H18.286q-7.46 0-12.873-5.413T0 640V530.286q0-7.46 5.413-12.873T18.286 512H128q7.46 0 12.873 5.413t5.413 12.873zm0-219.429v109.714q0 7.461-5.413 12.874T128 438.857H18.286q-7.46 0-12.873-5.412T0 420.57V310.857q0-7.46 5.413-12.873t12.873-5.413H128q7.46 0 12.873 5.413t5.413 12.873zM1024 749.714V859.43q0 7.46-5.413 12.873t-12.873 5.412h-768q-7.46 0-12.873-5.412t-5.412-12.873V749.714q0-7.46 5.412-12.873t12.873-5.412h768q7.46 0 12.873 5.412t5.413 12.873zM146.286 91.43v109.714q0 7.46-5.413 12.873T128 219.429H18.286q-7.46 0-12.873-5.413T0 201.143V91.429q0-7.461 5.413-12.874t12.873-5.412H128q7.46 0 12.873 5.412t5.413 12.874zM1024 530.286V640q0 7.46-5.413 12.873t-12.873 5.413h-768q-7.46 0-12.873-5.413T219.43 640V530.286q0-7.46 5.412-12.873T237.714 512h768q7.46 0 12.873 5.413t5.413 12.873zm0-219.429v109.714q0 7.461-5.413 12.874t-12.873 5.412h-768q-7.46 0-12.873-5.412t-5.412-12.874V310.857q0-7.46 5.412-12.873t12.873-5.413h768q7.46 0 12.873 5.413t5.413 12.873zm0-219.428v109.714q0 7.46-5.413 12.873t-12.873 5.413h-768q-7.46 0-12.873-5.413t-5.412-12.873V91.429q0-7.461 5.412-12.874t12.873-5.412h768q7.46 0 12.873 5.412T1024 91.43z" />
    </svg>
  )
}
