import React from 'react'
import { styled, s } from 'styled'
import { TableHeader } from './TableHeader'
import { TableBody } from './TableBody'

const Table = styled.table(s('flex flex-column w-full h-full'), {
  height: 442,
  overflowY: 'scroll',
  '&:not(:hover)': {
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
})

function TableBase({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  page,
  tBodyStyle,
}) {
  return (
    <Table {...getTableProps()}>
      <TableHeader headerGroups={headerGroups} />
      <TableBody
        tBodyStyle={tBodyStyle}
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
        page={page}
      />
    </Table>
  )
}

export { TableBase }
