/* eslint-disable react/jsx-key */
import React from "react";
import { prop } from "ramda";
import { styled, s } from "styled";

const Tr = styled.tr(s("flex bg-gray-100"), {
  "&:last-child": { "& td": { borderTop: 0 } },
});

const Th = styled.th(s("pr-3 w-full text-left"), prop("tableHeaderStyles"));

const Head = styled.div(s("text-2xl font-semibold"));

function TableHeader({ headerGroups }) {
  return (
    <thead style={s("sticky z-1 pin-t-0 mb-4")}>
      {headerGroups.map((headerGroup) => (
        <Tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <Th
              tableHeaderStyles={prop("tableHeaderStyles", column)}
              {...column.getHeaderProps()}
            >
              <Head>
                {column.render("Header")}
                {column.canSort ? null : ""}
              </Head>
            </Th>
          ))}
        </Tr>
      ))}
    </thead>
  );
}

export { TableHeader };
