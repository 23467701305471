import React from 'react'
import { styled, s } from 'styled'

import { BarChartIcon } from './BarChartIcon'
import { ListIcon } from './ListIcon'

const ViewIcon = styled.button(
  s('border-0', { background: 'inherit', cursor: 'pointer' }),
  ({ selected }) => ({ pointerEvents: selected ? 'none' : 'initial' })
)

export const ViewSwitcher = ({ currentView, switchToView, ...props }) => {
  return (
    <div style={s('flex justitfy-between items-center')} {...props}>
      <h2 style={s('flex-1 pl-4')}>Results</h2>
      <div style={s('flex justify-between items-center w-6 mr-4')}>
        <ViewIcon
          onClick={() => switchToView('chart')}
          selected={currentView === 'chart'}
        >
          <BarChartIcon fill={currentView === 'chart' ? '#86347D' : '#3b3b3b'} />
        </ViewIcon>
        <ViewIcon
          onClick={() => switchToView('list')}
          selected={currentView === 'list'}
        >
          <ListIcon fill={currentView === 'list' ? '#86347D' : '#3b3b3b'} />
        </ViewIcon>
      </div>
    </div>
  )
}
