import * as React from 'react'

export const BarChartIcon = ({ width = 32, height = 32, ...props }) => {
  return (
    <svg
      className="prefix__icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <path d="M256 896H128a42.667 42.667 0 01-42.667-42.667V512A42.667 42.667 0 01128 469.333h128A42.667 42.667 0 01298.667 512v341.333A42.667 42.667 0 01256 896zm298.667 0h-128A42.667 42.667 0 01384 853.333V128a42.667 42.667 0 0142.667-42.667h128A42.667 42.667 0 01597.333 128v725.333A42.667 42.667 0 01554.667 896zm298.666 0h-128a42.667 42.667 0 01-42.666-42.667V384a42.667 42.667 0 0142.666-42.667h128A42.667 42.667 0 01896 384v469.333A42.667 42.667 0 01853.333 896z" />
    </svg>
  )
}
