import React from 'react'
import { styled, s } from 'styled'

const Tr = styled.tr(s('flex no-decoration text-xl h-3 w-full border-none'), {
  borderBottom: '2px solid',
  '&:first-of-type': { borderTop: `2px solid ${s('border-gray-400').borderColor}` },
  '&:hover': { backgroundColor: '#fff' },
  ...s('border-gray-400'),
})

const Td = styled.td(s('flex items-center w-1/5 pt-2 text-left m-0'))

const Tbody = styled.tbody(s('text-center h-full'))

function TableBody({ getTableBodyProps, prepareRow, page }) {
  return (
    <Tbody {...getTableBodyProps()}>
      {page.map((row) => {
        prepareRow(row)

        return (
          <Tr key={row.id}>
            {row.cells.map((cell, index) => {
              return (
                <Td key={index} {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </Td>
              )
            })}
          </Tr>
        )
      })}
    </Tbody>
  )
}

export { TableBody }
