// THIS FILE IS AUTOGENERATED VIA HEADWIND. DO NOT MODIFY IT DIRECTLY. MANUAL CHANGES
// WILL BE OVERWRITTEN THE NEXT TIME THIS FILE IS AUTOGENERATED.

const headwindUtilities = Object.freeze({
  'content-start': {
    alignContent: 'flex-start',
  },
  'content-end': {
    alignContent: 'flex-end',
  },
  'content-center': {
    alignContent: 'center',
  },
  'content-between': {
    alignContent: 'space-between',
  },
  'content-around': {
    alignContent: 'space-around',
  },
  'items-stretch': {
    alignItems: 'stretch',
  },
  'items-start': {
    alignItems: 'flex-start',
  },
  'items-end': {
    alignItems: 'flex-end',
  },
  'items-center': {
    alignItems: 'center',
  },
  'items-baseline': {
    alignItems: 'baseline',
  },
  'self-auto': {
    alignSelf: 'auto',
  },
  'self-start': {
    alignSelf: 'flex-start',
  },
  'self-end': {
    alignSelf: 'flex-end',
  },
  'self-center': {
    alignSelf: 'center',
  },
  'self-stretch': {
    alignSelf: 'stretch',
  },
  'self-baseline': {
    alignSelf: 'baseline',
  },
  'bg-transparent': {
    backgroundColor: 'transparent',
  },
  'bg-gray-50': {
    backgroundColor: '#FAFAFA',
  },
  'bg-gray-100': {
    backgroundColor: '#F4F4F5',
  },
  'bg-gray-200': {
    backgroundColor: '#E4E4E7',
  },
  'bg-gray-300': {
    backgroundColor: '#D4D4D8',
  },
  'bg-gray-400': {
    backgroundColor: '#A1A1AA',
  },
  'bg-gray-500': {
    backgroundColor: '#71717A',
  },
  'bg-gray-600': {
    backgroundColor: '#52525B',
  },
  'bg-gray-700': {
    backgroundColor: '#3F3F46',
  },
  'bg-gray-800': {
    backgroundColor: '#27272A',
  },
  'bg-gray-900': {
    backgroundColor: '#18181B',
  },
  'bg-rose-50': {
    backgroundColor: '#fff1f2',
  },
  'bg-rose-100': {
    backgroundColor: '#ffe4e6',
  },
  'bg-rose-200': {
    backgroundColor: '#fecdd3',
  },
  'bg-rose-300': {
    backgroundColor: '#fda4af',
  },
  'bg-rose-400': {
    backgroundColor: '#fb7185',
  },
  'bg-rose-500': {
    backgroundColor: '#f43f5e',
  },
  'bg-rose-600': {
    backgroundColor: '#e11d48',
  },
  'bg-rose-700': {
    backgroundColor: '#be123c',
  },
  'bg-rose-800': {
    backgroundColor: '#9f1239',
  },
  'bg-rose-900': {
    backgroundColor: '#881337',
  },
  'bg-pink-50': {
    backgroundColor: '#fdf2f8',
  },
  'bg-pink-100': {
    backgroundColor: '#fce7f3',
  },
  'bg-pink-200': {
    backgroundColor: '#fbcfe8',
  },
  'bg-pink-300': {
    backgroundColor: '#f9a8d4',
  },
  'bg-pink-400': {
    backgroundColor: '#f472b6',
  },
  'bg-pink-500': {
    backgroundColor: '#ec4899',
  },
  'bg-pink-600': {
    backgroundColor: '#db2777',
  },
  'bg-pink-700': {
    backgroundColor: '#be185d',
  },
  'bg-pink-800': {
    backgroundColor: '#9d174d',
  },
  'bg-pink-900': {
    backgroundColor: '#831843',
  },
  'bg-fuchsia-50': {
    backgroundColor: '#fdf4ff',
  },
  'bg-fuchsia-100': {
    backgroundColor: '#fae8ff',
  },
  'bg-fuchsia-200': {
    backgroundColor: '#f5d0fe',
  },
  'bg-fuchsia-300': {
    backgroundColor: '#f0abfc',
  },
  'bg-fuchsia-400': {
    backgroundColor: '#e879f9',
  },
  'bg-fuchsia-500': {
    backgroundColor: '#d946ef',
  },
  'bg-fuchsia-600': {
    backgroundColor: '#c026d3',
  },
  'bg-fuchsia-700': {
    backgroundColor: '#a21caf',
  },
  'bg-fuchsia-800': {
    backgroundColor: '#86198f',
  },
  'bg-fuchsia-900': {
    backgroundColor: '#701a75',
  },
  'bg-purple-50': {
    backgroundColor: '#faf5ff',
  },
  'bg-purple-100': {
    backgroundColor: '#f3e8ff',
  },
  'bg-purple-200': {
    backgroundColor: '#e9d5ff',
  },
  'bg-purple-300': {
    backgroundColor: '#d8b4fe',
  },
  'bg-purple-400': {
    backgroundColor: '#c084fc',
  },
  'bg-purple-500': {
    backgroundColor: '#a855f7',
  },
  'bg-purple-600': {
    backgroundColor: '#9333ea',
  },
  'bg-purple-700': {
    backgroundColor: '#7e22ce',
  },
  'bg-purple-800': {
    backgroundColor: '#6b21a8',
  },
  'bg-purple-900': {
    backgroundColor: '#581c87',
  },
  'bg-violet-50': {
    backgroundColor: '#f5f3ff',
  },
  'bg-violet-100': {
    backgroundColor: '#ede9fe',
  },
  'bg-violet-200': {
    backgroundColor: '#ddd6fe',
  },
  'bg-violet-300': {
    backgroundColor: '#c4b5fd',
  },
  'bg-violet-400': {
    backgroundColor: '#a78bfa',
  },
  'bg-violet-500': {
    backgroundColor: '#8b5cf6',
  },
  'bg-violet-600': {
    backgroundColor: '#7c3aed',
  },
  'bg-violet-700': {
    backgroundColor: '#6d28d9',
  },
  'bg-violet-800': {
    backgroundColor: '#5b21b6',
  },
  'bg-violet-900': {
    backgroundColor: '#4c1d95',
  },
  'bg-indigo-50': {
    backgroundColor: '#eef2ff',
  },
  'bg-indigo-100': {
    backgroundColor: '#e0e7ff',
  },
  'bg-indigo-200': {
    backgroundColor: '#c7d2fe',
  },
  'bg-indigo-300': {
    backgroundColor: '#a5b4fc',
  },
  'bg-indigo-400': {
    backgroundColor: '#818cf8',
  },
  'bg-indigo-500': {
    backgroundColor: '#6366f1',
  },
  'bg-indigo-600': {
    backgroundColor: '#4f46e5',
  },
  'bg-indigo-700': {
    backgroundColor: '#4338ca',
  },
  'bg-indigo-800': {
    backgroundColor: '#3730a3',
  },
  'bg-indigo-900': {
    backgroundColor: '#312e81',
  },
  'bg-blue-50': {
    backgroundColor: '#eff6ff',
  },
  'bg-blue-100': {
    backgroundColor: '#dbeafe',
  },
  'bg-blue-200': {
    backgroundColor: '#bfdbfe',
  },
  'bg-blue-300': {
    backgroundColor: '#93c5fd',
  },
  'bg-blue-400': {
    backgroundColor: '#60a5fa',
  },
  'bg-blue-500': {
    backgroundColor: '#3b82f6',
  },
  'bg-blue-600': {
    backgroundColor: '#2563eb',
  },
  'bg-blue-700': {
    backgroundColor: '#1d4ed8',
  },
  'bg-blue-800': {
    backgroundColor: '#1e40af',
  },
  'bg-blue-900': {
    backgroundColor: '#1e3a8a',
  },
  'bg-lightBlue-50': {
    backgroundColor: '#f0f9ff',
  },
  'bg-lightBlue-100': {
    backgroundColor: '#e0f2fe',
  },
  'bg-lightBlue-200': {
    backgroundColor: '#bae6fd',
  },
  'bg-lightBlue-300': {
    backgroundColor: '#7dd3fc',
  },
  'bg-lightBlue-400': {
    backgroundColor: '#38bdf8',
  },
  'bg-lightBlue-500': {
    backgroundColor: '#0ea5e9',
  },
  'bg-lightBlue-600': {
    backgroundColor: '#0284c7',
  },
  'bg-lightBlue-700': {
    backgroundColor: '#0369a1',
  },
  'bg-lightBlue-800': {
    backgroundColor: '#075985',
  },
  'bg-lightBlue-900': {
    backgroundColor: '#0c4a6e',
  },
  'bg-cyan-50': {
    backgroundColor: '#ecfeff',
  },
  'bg-cyan-100': {
    backgroundColor: '#cffafe',
  },
  'bg-cyan-200': {
    backgroundColor: '#a5f3fc',
  },
  'bg-cyan-300': {
    backgroundColor: '#67e8f9',
  },
  'bg-cyan-400': {
    backgroundColor: '#22d3ee',
  },
  'bg-cyan-500': {
    backgroundColor: '#06b6d4',
  },
  'bg-cyan-600': {
    backgroundColor: '#0891b2',
  },
  'bg-cyan-700': {
    backgroundColor: '#0e7490',
  },
  'bg-cyan-800': {
    backgroundColor: '#155e75',
  },
  'bg-cyan-900': {
    backgroundColor: '#164e63',
  },
  'bg-teal-50': {
    backgroundColor: '#f0fdfa',
  },
  'bg-teal-100': {
    backgroundColor: '#ccfbf1',
  },
  'bg-teal-200': {
    backgroundColor: '#99f6e4',
  },
  'bg-teal-300': {
    backgroundColor: '#5eead4',
  },
  'bg-teal-400': {
    backgroundColor: '#2dd4bf',
  },
  'bg-teal-500': {
    backgroundColor: '#14b8a6',
  },
  'bg-teal-600': {
    backgroundColor: '#0d9488',
  },
  'bg-teal-700': {
    backgroundColor: '#0f766e',
  },
  'bg-teal-800': {
    backgroundColor: '#115e59',
  },
  'bg-teal-900': {
    backgroundColor: '#134e4a',
  },
  'bg-emerald-50': {
    backgroundColor: '#ecfdf5',
  },
  'bg-emerald-100': {
    backgroundColor: '#d1fae5',
  },
  'bg-emerald-200': {
    backgroundColor: '#a7f3d0',
  },
  'bg-emerald-300': {
    backgroundColor: '#6ee7b7',
  },
  'bg-emerald-400': {
    backgroundColor: '#34d399',
  },
  'bg-emerald-500': {
    backgroundColor: '#10b981',
  },
  'bg-emerald-600': {
    backgroundColor: '#059669',
  },
  'bg-emerald-700': {
    backgroundColor: '#047857',
  },
  'bg-emerald-800': {
    backgroundColor: '#065f46',
  },
  'bg-emerald-900': {
    backgroundColor: '#064e3b',
  },
  'bg-green-50': {
    backgroundColor: '#f0fdf4',
  },
  'bg-green-100': {
    backgroundColor: '#dcfce7',
  },
  'bg-green-200': {
    backgroundColor: '#bbf7d0',
  },
  'bg-green-300': {
    backgroundColor: '#86efac',
  },
  'bg-green-400': {
    backgroundColor: '#4ade80',
  },
  'bg-green-500': {
    backgroundColor: '#22c55e',
  },
  'bg-green-600': {
    backgroundColor: '#16a34a',
  },
  'bg-green-700': {
    backgroundColor: '#15803d',
  },
  'bg-green-800': {
    backgroundColor: '#166534',
  },
  'bg-green-900': {
    backgroundColor: '#14532d',
  },
  'bg-lime-50': {
    backgroundColor: '#f7fee7',
  },
  'bg-lime-100': {
    backgroundColor: '#ecfccb',
  },
  'bg-lime-200': {
    backgroundColor: '#d9f99d',
  },
  'bg-lime-300': {
    backgroundColor: '#bef264',
  },
  'bg-lime-400': {
    backgroundColor: '#a3e635',
  },
  'bg-lime-500': {
    backgroundColor: '#84cc16',
  },
  'bg-lime-600': {
    backgroundColor: '#65a30d',
  },
  'bg-lime-700': {
    backgroundColor: '#4d7c0f',
  },
  'bg-lime-800': {
    backgroundColor: '#3f6212',
  },
  'bg-lime-900': {
    backgroundColor: '#365314',
  },
  'bg-yellow-50': {
    backgroundColor: '#fefce8',
  },
  'bg-yellow-100': {
    backgroundColor: '#fef9c3',
  },
  'bg-yellow-200': {
    backgroundColor: '#fef08a',
  },
  'bg-yellow-300': {
    backgroundColor: '#fde047',
  },
  'bg-yellow-400': {
    backgroundColor: '#facc15',
  },
  'bg-yellow-500': {
    backgroundColor: '#eab308',
  },
  'bg-yellow-600': {
    backgroundColor: '#ca8a04',
  },
  'bg-yellow-700': {
    backgroundColor: '#a16207',
  },
  'bg-yellow-800': {
    backgroundColor: '#854d0e',
  },
  'bg-yellow-900': {
    backgroundColor: '#713f12',
  },
  'bg-amber-50': {
    backgroundColor: '#fffbeb',
  },
  'bg-amber-100': {
    backgroundColor: '#fef3c7',
  },
  'bg-amber-200': {
    backgroundColor: '#fde68a',
  },
  'bg-amber-300': {
    backgroundColor: '#fcd34d',
  },
  'bg-amber-400': {
    backgroundColor: '#fbbf24',
  },
  'bg-amber-500': {
    backgroundColor: '#f59e0b',
  },
  'bg-amber-600': {
    backgroundColor: '#d97706',
  },
  'bg-amber-700': {
    backgroundColor: '#b45309',
  },
  'bg-amber-800': {
    backgroundColor: '#92400e',
  },
  'bg-amber-900': {
    backgroundColor: '#78350f',
  },
  'bg-orange-50': {
    backgroundColor: '#fff7ed',
  },
  'bg-orange-100': {
    backgroundColor: '#ffedd5',
  },
  'bg-orange-200': {
    backgroundColor: '#fed7aa',
  },
  'bg-orange-300': {
    backgroundColor: '#fdba74',
  },
  'bg-orange-400': {
    backgroundColor: '#fb923c',
  },
  'bg-orange-500': {
    backgroundColor: '#f97316',
  },
  'bg-orange-600': {
    backgroundColor: '#ea580c',
  },
  'bg-orange-700': {
    backgroundColor: '#c2410c',
  },
  'bg-orange-800': {
    backgroundColor: '#9a3412',
  },
  'bg-orange-900': {
    backgroundColor: '#7c2d12',
  },
  'bg-red-50': {
    backgroundColor: '#fef2f2',
  },
  'bg-red-100': {
    backgroundColor: '#fee2e2',
  },
  'bg-red-200': {
    backgroundColor: '#fecaca',
  },
  'bg-red-300': {
    backgroundColor: '#fca5a5',
  },
  'bg-red-400': {
    backgroundColor: '#f87171',
  },
  'bg-red-500': {
    backgroundColor: '#ef4444',
  },
  'bg-red-600': {
    backgroundColor: '#dc2626',
  },
  'bg-red-700': {
    backgroundColor: '#b91c1c',
  },
  'bg-red-800': {
    backgroundColor: '#991b1b',
  },
  'bg-red-900': {
    backgroundColor: '#7f1d1d',
  },
  'border-transparent': {
    borderColor: 'transparent',
  },
  'border-gray-50': {
    borderColor: '#FAFAFA',
  },
  'border-gray-100': {
    borderColor: '#F4F4F5',
  },
  'border-gray-200': {
    borderColor: '#E4E4E7',
  },
  'border-gray-300': {
    borderColor: '#D4D4D8',
  },
  'border-gray-400': {
    borderColor: '#A1A1AA',
  },
  'border-gray-500': {
    borderColor: '#71717A',
  },
  'border-gray-600': {
    borderColor: '#52525B',
  },
  'border-gray-700': {
    borderColor: '#3F3F46',
  },
  'border-gray-800': {
    borderColor: '#27272A',
  },
  'border-gray-900': {
    borderColor: '#18181B',
  },
  'border-rose-50': {
    borderColor: '#fff1f2',
  },
  'border-rose-100': {
    borderColor: '#ffe4e6',
  },
  'border-rose-200': {
    borderColor: '#fecdd3',
  },
  'border-rose-300': {
    borderColor: '#fda4af',
  },
  'border-rose-400': {
    borderColor: '#fb7185',
  },
  'border-rose-500': {
    borderColor: '#f43f5e',
  },
  'border-rose-600': {
    borderColor: '#e11d48',
  },
  'border-rose-700': {
    borderColor: '#be123c',
  },
  'border-rose-800': {
    borderColor: '#9f1239',
  },
  'border-rose-900': {
    borderColor: '#881337',
  },
  'border-pink-50': {
    borderColor: '#fdf2f8',
  },
  'border-pink-100': {
    borderColor: '#fce7f3',
  },
  'border-pink-200': {
    borderColor: '#fbcfe8',
  },
  'border-pink-300': {
    borderColor: '#f9a8d4',
  },
  'border-pink-400': {
    borderColor: '#f472b6',
  },
  'border-pink-500': {
    borderColor: '#ec4899',
  },
  'border-pink-600': {
    borderColor: '#db2777',
  },
  'border-pink-700': {
    borderColor: '#be185d',
  },
  'border-pink-800': {
    borderColor: '#9d174d',
  },
  'border-pink-900': {
    borderColor: '#831843',
  },
  'border-fuchsia-50': {
    borderColor: '#fdf4ff',
  },
  'border-fuchsia-100': {
    borderColor: '#fae8ff',
  },
  'border-fuchsia-200': {
    borderColor: '#f5d0fe',
  },
  'border-fuchsia-300': {
    borderColor: '#f0abfc',
  },
  'border-fuchsia-400': {
    borderColor: '#e879f9',
  },
  'border-fuchsia-500': {
    borderColor: '#d946ef',
  },
  'border-fuchsia-600': {
    borderColor: '#c026d3',
  },
  'border-fuchsia-700': {
    borderColor: '#a21caf',
  },
  'border-fuchsia-800': {
    borderColor: '#86198f',
  },
  'border-fuchsia-900': {
    borderColor: '#701a75',
  },
  'border-purple-50': {
    borderColor: '#faf5ff',
  },
  'border-purple-100': {
    borderColor: '#f3e8ff',
  },
  'border-purple-200': {
    borderColor: '#e9d5ff',
  },
  'border-purple-300': {
    borderColor: '#d8b4fe',
  },
  'border-purple-400': {
    borderColor: '#c084fc',
  },
  'border-purple-500': {
    borderColor: '#a855f7',
  },
  'border-purple-600': {
    borderColor: '#9333ea',
  },
  'border-purple-700': {
    borderColor: '#7e22ce',
  },
  'border-purple-800': {
    borderColor: '#6b21a8',
  },
  'border-purple-900': {
    borderColor: '#581c87',
  },
  'border-violet-50': {
    borderColor: '#f5f3ff',
  },
  'border-violet-100': {
    borderColor: '#ede9fe',
  },
  'border-violet-200': {
    borderColor: '#ddd6fe',
  },
  'border-violet-300': {
    borderColor: '#c4b5fd',
  },
  'border-violet-400': {
    borderColor: '#a78bfa',
  },
  'border-violet-500': {
    borderColor: '#8b5cf6',
  },
  'border-violet-600': {
    borderColor: '#7c3aed',
  },
  'border-violet-700': {
    borderColor: '#6d28d9',
  },
  'border-violet-800': {
    borderColor: '#5b21b6',
  },
  'border-violet-900': {
    borderColor: '#4c1d95',
  },
  'border-indigo-50': {
    borderColor: '#eef2ff',
  },
  'border-indigo-100': {
    borderColor: '#e0e7ff',
  },
  'border-indigo-200': {
    borderColor: '#c7d2fe',
  },
  'border-indigo-300': {
    borderColor: '#a5b4fc',
  },
  'border-indigo-400': {
    borderColor: '#818cf8',
  },
  'border-indigo-500': {
    borderColor: '#6366f1',
  },
  'border-indigo-600': {
    borderColor: '#4f46e5',
  },
  'border-indigo-700': {
    borderColor: '#4338ca',
  },
  'border-indigo-800': {
    borderColor: '#3730a3',
  },
  'border-indigo-900': {
    borderColor: '#312e81',
  },
  'border-blue-50': {
    borderColor: '#eff6ff',
  },
  'border-blue-100': {
    borderColor: '#dbeafe',
  },
  'border-blue-200': {
    borderColor: '#bfdbfe',
  },
  'border-blue-300': {
    borderColor: '#93c5fd',
  },
  'border-blue-400': {
    borderColor: '#60a5fa',
  },
  'border-blue-500': {
    borderColor: '#3b82f6',
  },
  'border-blue-600': {
    borderColor: '#2563eb',
  },
  'border-blue-700': {
    borderColor: '#1d4ed8',
  },
  'border-blue-800': {
    borderColor: '#1e40af',
  },
  'border-blue-900': {
    borderColor: '#1e3a8a',
  },
  'border-lightBlue-50': {
    borderColor: '#f0f9ff',
  },
  'border-lightBlue-100': {
    borderColor: '#e0f2fe',
  },
  'border-lightBlue-200': {
    borderColor: '#bae6fd',
  },
  'border-lightBlue-300': {
    borderColor: '#7dd3fc',
  },
  'border-lightBlue-400': {
    borderColor: '#38bdf8',
  },
  'border-lightBlue-500': {
    borderColor: '#0ea5e9',
  },
  'border-lightBlue-600': {
    borderColor: '#0284c7',
  },
  'border-lightBlue-700': {
    borderColor: '#0369a1',
  },
  'border-lightBlue-800': {
    borderColor: '#075985',
  },
  'border-lightBlue-900': {
    borderColor: '#0c4a6e',
  },
  'border-cyan-50': {
    borderColor: '#ecfeff',
  },
  'border-cyan-100': {
    borderColor: '#cffafe',
  },
  'border-cyan-200': {
    borderColor: '#a5f3fc',
  },
  'border-cyan-300': {
    borderColor: '#67e8f9',
  },
  'border-cyan-400': {
    borderColor: '#22d3ee',
  },
  'border-cyan-500': {
    borderColor: '#06b6d4',
  },
  'border-cyan-600': {
    borderColor: '#0891b2',
  },
  'border-cyan-700': {
    borderColor: '#0e7490',
  },
  'border-cyan-800': {
    borderColor: '#155e75',
  },
  'border-cyan-900': {
    borderColor: '#164e63',
  },
  'border-teal-50': {
    borderColor: '#f0fdfa',
  },
  'border-teal-100': {
    borderColor: '#ccfbf1',
  },
  'border-teal-200': {
    borderColor: '#99f6e4',
  },
  'border-teal-300': {
    borderColor: '#5eead4',
  },
  'border-teal-400': {
    borderColor: '#2dd4bf',
  },
  'border-teal-500': {
    borderColor: '#14b8a6',
  },
  'border-teal-600': {
    borderColor: '#0d9488',
  },
  'border-teal-700': {
    borderColor: '#0f766e',
  },
  'border-teal-800': {
    borderColor: '#115e59',
  },
  'border-teal-900': {
    borderColor: '#134e4a',
  },
  'border-emerald-50': {
    borderColor: '#ecfdf5',
  },
  'border-emerald-100': {
    borderColor: '#d1fae5',
  },
  'border-emerald-200': {
    borderColor: '#a7f3d0',
  },
  'border-emerald-300': {
    borderColor: '#6ee7b7',
  },
  'border-emerald-400': {
    borderColor: '#34d399',
  },
  'border-emerald-500': {
    borderColor: '#10b981',
  },
  'border-emerald-600': {
    borderColor: '#059669',
  },
  'border-emerald-700': {
    borderColor: '#047857',
  },
  'border-emerald-800': {
    borderColor: '#065f46',
  },
  'border-emerald-900': {
    borderColor: '#064e3b',
  },
  'border-green-50': {
    borderColor: '#f0fdf4',
  },
  'border-green-100': {
    borderColor: '#dcfce7',
  },
  'border-green-200': {
    borderColor: '#bbf7d0',
  },
  'border-green-300': {
    borderColor: '#86efac',
  },
  'border-green-400': {
    borderColor: '#4ade80',
  },
  'border-green-500': {
    borderColor: '#22c55e',
  },
  'border-green-600': {
    borderColor: '#16a34a',
  },
  'border-green-700': {
    borderColor: '#15803d',
  },
  'border-green-800': {
    borderColor: '#166534',
  },
  'border-green-900': {
    borderColor: '#14532d',
  },
  'border-lime-50': {
    borderColor: '#f7fee7',
  },
  'border-lime-100': {
    borderColor: '#ecfccb',
  },
  'border-lime-200': {
    borderColor: '#d9f99d',
  },
  'border-lime-300': {
    borderColor: '#bef264',
  },
  'border-lime-400': {
    borderColor: '#a3e635',
  },
  'border-lime-500': {
    borderColor: '#84cc16',
  },
  'border-lime-600': {
    borderColor: '#65a30d',
  },
  'border-lime-700': {
    borderColor: '#4d7c0f',
  },
  'border-lime-800': {
    borderColor: '#3f6212',
  },
  'border-lime-900': {
    borderColor: '#365314',
  },
  'border-yellow-50': {
    borderColor: '#fefce8',
  },
  'border-yellow-100': {
    borderColor: '#fef9c3',
  },
  'border-yellow-200': {
    borderColor: '#fef08a',
  },
  'border-yellow-300': {
    borderColor: '#fde047',
  },
  'border-yellow-400': {
    borderColor: '#facc15',
  },
  'border-yellow-500': {
    borderColor: '#eab308',
  },
  'border-yellow-600': {
    borderColor: '#ca8a04',
  },
  'border-yellow-700': {
    borderColor: '#a16207',
  },
  'border-yellow-800': {
    borderColor: '#854d0e',
  },
  'border-yellow-900': {
    borderColor: '#713f12',
  },
  'border-amber-50': {
    borderColor: '#fffbeb',
  },
  'border-amber-100': {
    borderColor: '#fef3c7',
  },
  'border-amber-200': {
    borderColor: '#fde68a',
  },
  'border-amber-300': {
    borderColor: '#fcd34d',
  },
  'border-amber-400': {
    borderColor: '#fbbf24',
  },
  'border-amber-500': {
    borderColor: '#f59e0b',
  },
  'border-amber-600': {
    borderColor: '#d97706',
  },
  'border-amber-700': {
    borderColor: '#b45309',
  },
  'border-amber-800': {
    borderColor: '#92400e',
  },
  'border-amber-900': {
    borderColor: '#78350f',
  },
  'border-orange-50': {
    borderColor: '#fff7ed',
  },
  'border-orange-100': {
    borderColor: '#ffedd5',
  },
  'border-orange-200': {
    borderColor: '#fed7aa',
  },
  'border-orange-300': {
    borderColor: '#fdba74',
  },
  'border-orange-400': {
    borderColor: '#fb923c',
  },
  'border-orange-500': {
    borderColor: '#f97316',
  },
  'border-orange-600': {
    borderColor: '#ea580c',
  },
  'border-orange-700': {
    borderColor: '#c2410c',
  },
  'border-orange-800': {
    borderColor: '#9a3412',
  },
  'border-orange-900': {
    borderColor: '#7c2d12',
  },
  'border-red-50': {
    borderColor: '#fef2f2',
  },
  'border-red-100': {
    borderColor: '#fee2e2',
  },
  'border-red-200': {
    borderColor: '#fecaca',
  },
  'border-red-300': {
    borderColor: '#fca5a5',
  },
  'border-red-400': {
    borderColor: '#f87171',
  },
  'border-red-500': {
    borderColor: '#ef4444',
  },
  'border-red-600': {
    borderColor: '#dc2626',
  },
  'border-red-700': {
    borderColor: '#b91c1c',
  },
  'border-red-800': {
    borderColor: '#991b1b',
  },
  'border-red-900': {
    borderColor: '#7f1d1d',
  },
  'rounded-none': {
    borderRadius: 0,
  },
  'rounded-t-none': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  'rounded-b-none': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  'rounded-l-none': {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  'rounded-r-none': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  'rounded-tl-none': {
    borderTopLeftRadius: 0,
  },
  'rounded-tr-none': {
    borderTopRightRadius: 0,
  },
  'rounded-bl-none': {
    borderBottomLeftRadius: 0,
  },
  'rounded-br-none': {
    borderBottomRightRadius: 0,
  },
  'rounded-sm': {
    borderRadius: 2,
  },
  'rounded-t-sm': {
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
  },
  'rounded-b-sm': {
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
  },
  'rounded-l-sm': {
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
  },
  'rounded-r-sm': {
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
  },
  'rounded-tl-sm': {
    borderTopLeftRadius: 2,
  },
  'rounded-tr-sm': {
    borderTopRightRadius: 2,
  },
  'rounded-bl-sm': {
    borderBottomLeftRadius: 2,
  },
  'rounded-br-sm': {
    borderBottomRightRadius: 2,
  },
  'rounded-md': {
    borderRadius: 4,
  },
  'rounded-t-md': {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  'rounded-b-md': {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  'rounded-l-md': {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  'rounded-r-md': {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  'rounded-tl-md': {
    borderTopLeftRadius: 4,
  },
  'rounded-tr-md': {
    borderTopRightRadius: 4,
  },
  'rounded-bl-md': {
    borderBottomLeftRadius: 4,
  },
  'rounded-br-md': {
    borderBottomRightRadius: 4,
  },
  'rounded-lg': {
    borderRadius: 8,
  },
  'rounded-t-lg': {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  'rounded-b-lg': {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  'rounded-l-lg': {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  'rounded-r-lg': {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  'rounded-tl-lg': {
    borderTopLeftRadius: 8,
  },
  'rounded-tr-lg': {
    borderTopRightRadius: 8,
  },
  'rounded-bl-lg': {
    borderBottomLeftRadius: 8,
  },
  'rounded-br-lg': {
    borderBottomRightRadius: 8,
  },
  'rounded-xl': {
    borderRadius: 16,
  },
  'rounded-t-xl': {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  'rounded-b-xl': {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  'rounded-l-xl': {
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
  },
  'rounded-r-xl': {
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
  },
  'rounded-tl-xl': {
    borderTopLeftRadius: 16,
  },
  'rounded-tr-xl': {
    borderTopRightRadius: 16,
  },
  'rounded-bl-xl': {
    borderBottomLeftRadius: 16,
  },
  'rounded-br-xl': {
    borderBottomRightRadius: 16,
  },
  'rounded-2xl': {
    borderRadius: 24,
  },
  'rounded-t-2xl': {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  'rounded-b-2xl': {
    borderBottomLeftRadius: 24,
    borderBottomRightRadius: 24,
  },
  'rounded-l-2xl': {
    borderTopLeftRadius: 24,
    borderBottomLeftRadius: 24,
  },
  'rounded-r-2xl': {
    borderTopRightRadius: 24,
    borderBottomRightRadius: 24,
  },
  'rounded-tl-2xl': {
    borderTopLeftRadius: 24,
  },
  'rounded-tr-2xl': {
    borderTopRightRadius: 24,
  },
  'rounded-bl-2xl': {
    borderBottomLeftRadius: 24,
  },
  'rounded-br-2xl': {
    borderBottomRightRadius: 24,
  },
  'rounded-3xl': {
    borderRadius: 32,
  },
  'rounded-t-3xl': {
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
  },
  'rounded-b-3xl': {
    borderBottomLeftRadius: 32,
    borderBottomRightRadius: 32,
  },
  'rounded-l-3xl': {
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32,
  },
  'rounded-r-3xl': {
    borderTopRightRadius: 32,
    borderBottomRightRadius: 32,
  },
  'rounded-tl-3xl': {
    borderTopLeftRadius: 32,
  },
  'rounded-tr-3xl': {
    borderTopRightRadius: 32,
  },
  'rounded-bl-3xl': {
    borderBottomLeftRadius: 32,
  },
  'rounded-br-3xl': {
    borderBottomRightRadius: 32,
  },
  'rounded-full': {
    borderRadius: 300,
  },
  'rounded-t-full': {
    borderTopLeftRadius: 300,
    borderTopRightRadius: 300,
  },
  'rounded-b-full': {
    borderBottomLeftRadius: 300,
    borderBottomRightRadius: 300,
  },
  'rounded-l-full': {
    borderTopLeftRadius: 300,
    borderBottomLeftRadius: 300,
  },
  'rounded-r-full': {
    borderTopRightRadius: 300,
    borderBottomRightRadius: 300,
  },
  'rounded-tl-full': {
    borderTopLeftRadius: 300,
  },
  'rounded-tr-full': {
    borderTopRightRadius: 300,
  },
  'rounded-bl-full': {
    borderBottomLeftRadius: 300,
  },
  'rounded-br-full': {
    borderBottomRightRadius: 300,
  },
  'border-solid': {
    borderStyle: 'solid',
  },
  'border-dashed': {
    borderStyle: 'dashed',
  },
  'border-dotted': {
    borderStyle: 'dotted',
  },
  'border-0': {
    borderWidth: 0,
  },
  'border-t-0': {
    borderTopWidth: 0,
  },
  'border-b-0': {
    borderBottomWidth: 0,
  },
  'border-l-0': {
    borderLeftWidth: 0,
  },
  'border-r-0': {
    borderRightWidth: 0,
  },
  'border-x-0': {
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  'border-y-0': {
    borderTopWidth: 0,
    borderBottomWidth: 0,
  },
  'border-1': {
    borderWidth: 1,
  },
  'border-t-1': {
    borderTopWidth: 1,
  },
  'border-b-1': {
    borderBottomWidth: 1,
  },
  'border-l-1': {
    borderLeftWidth: 1,
  },
  'border-r-1': {
    borderRightWidth: 1,
  },
  'border-x-1': {
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  'border-y-1': {
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  'border-2': {
    borderWidth: 2,
  },
  'border-t-2': {
    borderTopWidth: 2,
  },
  'border-b-2': {
    borderBottomWidth: 2,
  },
  'border-l-2': {
    borderLeftWidth: 2,
  },
  'border-r-2': {
    borderRightWidth: 2,
  },
  'border-x-2': {
    borderLeftWidth: 2,
    borderRightWidth: 2,
  },
  'border-y-2': {
    borderTopWidth: 2,
    borderBottomWidth: 2,
  },
  'border-4': {
    borderWidth: 4,
  },
  'border-t-4': {
    borderTopWidth: 4,
  },
  'border-b-4': {
    borderBottomWidth: 4,
  },
  'border-l-4': {
    borderLeftWidth: 4,
  },
  'border-r-4': {
    borderRightWidth: 4,
  },
  'border-x-4': {
    borderLeftWidth: 4,
    borderRightWidth: 4,
  },
  'border-y-4': {
    borderTopWidth: 4,
    borderBottomWidth: 4,
  },
  'border-8': {
    borderWidth: 8,
  },
  'border-t-8': {
    borderTopWidth: 8,
  },
  'border-b-8': {
    borderBottomWidth: 8,
  },
  'border-l-8': {
    borderLeftWidth: 8,
  },
  'border-r-8': {
    borderRightWidth: 8,
  },
  'border-x-8': {
    borderLeftWidth: 8,
    borderRightWidth: 8,
  },
  'border-y-8': {
    borderTopWidth: 8,
    borderBottomWidth: 8,
  },
  'cursor-auto': {
    cursor: 'auto',
  },
  'cursor-default': {
    cursor: 'default',
  },
  'cursor-pointer': {
    cursor: 'pointer',
  },
  'cursor-wait': {
    cursor: 'wait',
  },
  'cursor-text': {
    cursor: 'text',
  },
  'cursor-move': {
    cursor: 'move',
  },
  'cursor-help': {
    cursor: 'help',
  },
  'cursor-not-allowed': {
    cursor: 'not-allowed',
  },
  debug: {
    borderWidth: 1,
    borderColor: 'red',
    borderStyle: 'solid',
  },
  hidden: {
    display: 'none',
  },
  flex: {
    display: 'flex',
  },
  block: {
    display: 'block',
  },
  inline: {
    display: 'inline',
  },
  grid: {
    display: 'grid',
  },
  'inline-block': {
    display: 'inline-block',
  },
  'inline-flex': {
    display: 'inline-flex',
  },
  table: {
    display: 'table',
  },
  'table-caption': {
    display: 'table-caption',
  },
  'table-cell': {
    display: 'table-cell',
  },
  'table-column': {
    display: 'table-column',
  },
  'table-column-group': {
    display: 'table-column-group',
  },
  'table-footer-group': {
    display: 'table-footer-group',
  },
  'table-row-group': {
    display: 'table-row-group',
  },
  'table-row': {
    display: 'table-row',
  },
  'flex-0': {
    flex: 0,
  },
  'flex-1': {
    flex: 1,
  },
  'flex-row': {
    flexDirection: 'row',
  },
  'flex-row-reverse': {
    flexDirection: 'row-reverse',
  },
  'flex-column': {
    flexDirection: 'column',
  },
  'flex-column-reverse': {
    flexDirection: 'column-reverse',
  },
  'flex-grow-0': {
    flexGrow: 0,
  },
  'flex-grow-1': {
    flexGrow: 1,
  },
  'flex-shrink-0': {
    flexShrink: 0,
  },
  'flex-shrink-1': {
    flexShrink: 1,
  },
  'flex-wrap': {
    flexWrap: 'wrap',
  },
  'flex-wrap-reverse': {
    flexWrap: 'wrap-reverse',
  },
  'flex-wrap-none': {
    flexWrap: 'nowrap',
  },
  'text-xs': {
    fontSize: 12,
  },
  'text-sm': {
    fontSize: 14,
  },
  'text-base': {
    fontSize: 16,
  },
  'text-lg': {
    fontSize: 18,
  },
  'text-xl': {
    fontSize: 20,
  },
  'text-2xl': {
    fontSize: 24,
  },
  'text-3xl': {
    fontSize: 30,
  },
  'text-4xl': {
    fontSize: 36,
  },
  'text-5xl': {
    fontSize: 48,
  },
  italic: {
    fontStyle: 'italic',
  },
  'no-italic': {
    fontStyle: 'normal',
  },
  'font-hairline': {
    fontWeight: 100,
  },
  'font-thin': {
    fontWeight: 200,
  },
  'font-light': {
    fontWeight: 300,
  },
  'font-normal': {
    fontWeight: 400,
  },
  'font-medium': {
    fontWeight: 500,
  },
  'font-semibold': {
    fontWeight: 600,
  },
  'font-bold': {
    fontWeight: 700,
  },
  'font-extrabold': {
    fontWeight: 800,
  },
  'font-black': {
    fontWeight: 900,
  },
  'h-0': {
    height: 0,
  },
  'h-1': {
    height: 16,
  },
  'h-2': {
    height: 32,
  },
  'h-3': {
    height: 48,
  },
  'h-4': {
    height: 64,
  },
  'h-5': {
    height: 80,
  },
  'h-6': {
    height: 96,
  },
  'h-8': {
    height: 128,
  },
  'h-10': {
    height: 160,
  },
  'h-12': {
    height: 192,
  },
  'h-16': {
    height: 256,
  },
  'h-24': {
    height: 384,
  },
  'h-32': {
    height: 512,
  },
  'h-48': {
    height: 768,
  },
  'h-64': {
    height: 1024,
  },
  'h-1px': {
    height: 1,
  },
  'h-1/2': {
    height: '50%',
  },
  'h-1/3': {
    height: '33.33333%',
  },
  'h-2/3': {
    height: '66.66667%',
  },
  'h-1/4': {
    height: '25%',
  },
  'h-3/4': {
    height: '75%',
  },
  'h-1/5': {
    height: '20%',
  },
  'h-2/5': {
    height: '40%',
  },
  'h-3/5': {
    height: '60%',
  },
  'h-4/5': {
    height: '80%',
  },
  'h-full': {
    height: '100%',
  },
  'h-a': {
    height: 'auto',
  },
  'justify-start': {
    justifyContent: 'flex-start',
  },
  'justify-end': {
    justifyContent: 'flex-end',
  },
  'justify-center': {
    justifyContent: 'center',
  },
  'justify-between': {
    justifyContent: 'space-between',
  },
  'justify-around': {
    justifyContent: 'space-around',
  },
  'justify-evenly': {
    justifyContent: 'space-evenly',
  },
  'tracking-tight': {
    letterSpacing: -0.5,
  },
  'tracking-normal': {
    letterSpacing: 0,
  },
  'tracking-wide': {
    letterSpacing: 0.5,
  },
  'leading-none': {
    lineHeight: 16,
  },
  'leading-tight': {
    lineHeight: 20,
  },
  'leading-normal': {
    lineHeight: 24,
  },
  'leading-loose': {
    lineHeight: 32,
  },
  'm-0': {
    margin: 0,
  },
  'mt-0': {
    marginTop: 0,
  },
  'mb-0': {
    marginBottom: 0,
  },
  'ml-0': {
    marginLeft: 0,
  },
  'mr-0': {
    marginRight: 0,
  },
  'mx-0': {
    marginLeft: 0,
    marginRight: 0,
  },
  'my-0': {
    marginTop: 0,
    marginBottom: 0,
  },
  'm-1': {
    margin: 4,
  },
  'mt-1': {
    marginTop: 4,
  },
  'mb-1': {
    marginBottom: 4,
  },
  'ml-1': {
    marginLeft: 4,
  },
  'mr-1': {
    marginRight: 4,
  },
  'mx-1': {
    marginLeft: 4,
    marginRight: 4,
  },
  'my-1': {
    marginTop: 4,
    marginBottom: 4,
  },
  'm-2': {
    margin: 8,
  },
  'mt-2': {
    marginTop: 8,
  },
  'mb-2': {
    marginBottom: 8,
  },
  'ml-2': {
    marginLeft: 8,
  },
  'mr-2': {
    marginRight: 8,
  },
  'mx-2': {
    marginLeft: 8,
    marginRight: 8,
  },
  'my-2': {
    marginTop: 8,
    marginBottom: 8,
  },
  'm-3': {
    margin: 12,
  },
  'mt-3': {
    marginTop: 12,
  },
  'mb-3': {
    marginBottom: 12,
  },
  'ml-3': {
    marginLeft: 12,
  },
  'mr-3': {
    marginRight: 12,
  },
  'mx-3': {
    marginLeft: 12,
    marginRight: 12,
  },
  'my-3': {
    marginTop: 12,
    marginBottom: 12,
  },
  'm-4': {
    margin: 16,
  },
  'mt-4': {
    marginTop: 16,
  },
  'mb-4': {
    marginBottom: 16,
  },
  'ml-4': {
    marginLeft: 16,
  },
  'mr-4': {
    marginRight: 16,
  },
  'mx-4': {
    marginLeft: 16,
    marginRight: 16,
  },
  'my-4': {
    marginTop: 16,
    marginBottom: 16,
  },
  'm-5': {
    margin: 20,
  },
  'mt-5': {
    marginTop: 20,
  },
  'mb-5': {
    marginBottom: 20,
  },
  'ml-5': {
    marginLeft: 20,
  },
  'mr-5': {
    marginRight: 20,
  },
  'mx-5': {
    marginLeft: 20,
    marginRight: 20,
  },
  'my-5': {
    marginTop: 20,
    marginBottom: 20,
  },
  'm-6': {
    margin: 24,
  },
  'mt-6': {
    marginTop: 24,
  },
  'mb-6': {
    marginBottom: 24,
  },
  'ml-6': {
    marginLeft: 24,
  },
  'mr-6': {
    marginRight: 24,
  },
  'mx-6': {
    marginLeft: 24,
    marginRight: 24,
  },
  'my-6': {
    marginTop: 24,
    marginBottom: 24,
  },
  'm-8': {
    margin: 32,
  },
  'mt-8': {
    marginTop: 32,
  },
  'mb-8': {
    marginBottom: 32,
  },
  'ml-8': {
    marginLeft: 32,
  },
  'mr-8': {
    marginRight: 32,
  },
  'mx-8': {
    marginLeft: 32,
    marginRight: 32,
  },
  'my-8': {
    marginTop: 32,
    marginBottom: 32,
  },
  'm-9': {
    margin: 36,
  },
  'mt-9': {
    marginTop: 36,
  },
  'mb-9': {
    marginBottom: 36,
  },
  'ml-9': {
    marginLeft: 36,
  },
  'mr-9': {
    marginRight: 36,
  },
  'mx-9': {
    marginLeft: 36,
    marginRight: 36,
  },
  'my-9': {
    marginTop: 36,
    marginBottom: 36,
  },
  'm-10': {
    margin: 40,
  },
  'mt-10': {
    marginTop: 40,
  },
  'mb-10': {
    marginBottom: 40,
  },
  'ml-10': {
    marginLeft: 40,
  },
  'mr-10': {
    marginRight: 40,
  },
  'mx-10': {
    marginLeft: 40,
    marginRight: 40,
  },
  'my-10': {
    marginTop: 40,
    marginBottom: 40,
  },
  'm-12': {
    margin: 48,
  },
  'mt-12': {
    marginTop: 48,
  },
  'mb-12': {
    marginBottom: 48,
  },
  'ml-12': {
    marginLeft: 48,
  },
  'mr-12': {
    marginRight: 48,
  },
  'mx-12': {
    marginLeft: 48,
    marginRight: 48,
  },
  'my-12': {
    marginTop: 48,
    marginBottom: 48,
  },
  'm-16': {
    margin: 64,
  },
  'mt-16': {
    marginTop: 64,
  },
  'mb-16': {
    marginBottom: 64,
  },
  'ml-16': {
    marginLeft: 64,
  },
  'mr-16': {
    marginRight: 64,
  },
  'mx-16': {
    marginLeft: 64,
    marginRight: 64,
  },
  'my-16': {
    marginTop: 64,
    marginBottom: 64,
  },
  'm-24': {
    margin: 96,
  },
  'mt-24': {
    marginTop: 96,
  },
  'mb-24': {
    marginBottom: 96,
  },
  'ml-24': {
    marginLeft: 96,
  },
  'mr-24': {
    marginRight: 96,
  },
  'mx-24': {
    marginLeft: 96,
    marginRight: 96,
  },
  'my-24': {
    marginTop: 96,
    marginBottom: 96,
  },
  'm-32': {
    margin: 128,
  },
  'mt-32': {
    marginTop: 128,
  },
  'mb-32': {
    marginBottom: 128,
  },
  'ml-32': {
    marginLeft: 128,
  },
  'mr-32': {
    marginRight: 128,
  },
  'mx-32': {
    marginLeft: 128,
    marginRight: 128,
  },
  'my-32': {
    marginTop: 128,
    marginBottom: 128,
  },
  'm-48': {
    margin: 192,
  },
  'mt-48': {
    marginTop: 192,
  },
  'mb-48': {
    marginBottom: 192,
  },
  'ml-48': {
    marginLeft: 192,
  },
  'mr-48': {
    marginRight: 192,
  },
  'mx-48': {
    marginLeft: 192,
    marginRight: 192,
  },
  'my-48': {
    marginTop: 192,
    marginBottom: 192,
  },
  'm-64': {
    margin: 256,
  },
  'mt-64': {
    marginTop: 256,
  },
  'mb-64': {
    marginBottom: 256,
  },
  'ml-64': {
    marginLeft: 256,
  },
  'mr-64': {
    marginRight: 256,
  },
  'mx-64': {
    marginLeft: 256,
    marginRight: 256,
  },
  'my-64': {
    marginTop: 256,
    marginBottom: 256,
  },
  'm-1px': {
    margin: 1,
  },
  'mt-1px': {
    marginTop: 1,
  },
  'mb-1px': {
    marginBottom: 1,
  },
  'ml-1px': {
    marginLeft: 1,
  },
  'mr-1px': {
    marginRight: 1,
  },
  'mx-1px': {
    marginLeft: 1,
    marginRight: 1,
  },
  'my-1px': {
    marginTop: 1,
    marginBottom: 1,
  },
  'm-1/2': {
    margin: '50%',
  },
  'mt-1/2': {
    marginTop: '50%',
  },
  'mb-1/2': {
    marginBottom: '50%',
  },
  'ml-1/2': {
    marginLeft: '50%',
  },
  'mr-1/2': {
    marginRight: '50%',
  },
  'mx-1/2': {
    marginLeft: '50%',
    marginRight: '50%',
  },
  'my-1/2': {
    marginTop: '50%',
    marginBottom: '50%',
  },
  'm-1/3': {
    margin: '33.33333%',
  },
  'mt-1/3': {
    marginTop: '33.33333%',
  },
  'mb-1/3': {
    marginBottom: '33.33333%',
  },
  'ml-1/3': {
    marginLeft: '33.33333%',
  },
  'mr-1/3': {
    marginRight: '33.33333%',
  },
  'mx-1/3': {
    marginLeft: '33.33333%',
    marginRight: '33.33333%',
  },
  'my-1/3': {
    marginTop: '33.33333%',
    marginBottom: '33.33333%',
  },
  'm-2/3': {
    margin: '66.66667%',
  },
  'mt-2/3': {
    marginTop: '66.66667%',
  },
  'mb-2/3': {
    marginBottom: '66.66667%',
  },
  'ml-2/3': {
    marginLeft: '66.66667%',
  },
  'mr-2/3': {
    marginRight: '66.66667%',
  },
  'mx-2/3': {
    marginLeft: '66.66667%',
    marginRight: '66.66667%',
  },
  'my-2/3': {
    marginTop: '66.66667%',
    marginBottom: '66.66667%',
  },
  'm-1/4': {
    margin: '25%',
  },
  'mt-1/4': {
    marginTop: '25%',
  },
  'mb-1/4': {
    marginBottom: '25%',
  },
  'ml-1/4': {
    marginLeft: '25%',
  },
  'mr-1/4': {
    marginRight: '25%',
  },
  'mx-1/4': {
    marginLeft: '25%',
    marginRight: '25%',
  },
  'my-1/4': {
    marginTop: '25%',
    marginBottom: '25%',
  },
  'm-3/4': {
    margin: '75%',
  },
  'mt-3/4': {
    marginTop: '75%',
  },
  'mb-3/4': {
    marginBottom: '75%',
  },
  'ml-3/4': {
    marginLeft: '75%',
  },
  'mr-3/4': {
    marginRight: '75%',
  },
  'mx-3/4': {
    marginLeft: '75%',
    marginRight: '75%',
  },
  'my-3/4': {
    marginTop: '75%',
    marginBottom: '75%',
  },
  'm-1/5': {
    margin: '20%',
  },
  'mt-1/5': {
    marginTop: '20%',
  },
  'mb-1/5': {
    marginBottom: '20%',
  },
  'ml-1/5': {
    marginLeft: '20%',
  },
  'mr-1/5': {
    marginRight: '20%',
  },
  'mx-1/5': {
    marginLeft: '20%',
    marginRight: '20%',
  },
  'my-1/5': {
    marginTop: '20%',
    marginBottom: '20%',
  },
  'm-2/5': {
    margin: '40%',
  },
  'mt-2/5': {
    marginTop: '40%',
  },
  'mb-2/5': {
    marginBottom: '40%',
  },
  'ml-2/5': {
    marginLeft: '40%',
  },
  'mr-2/5': {
    marginRight: '40%',
  },
  'mx-2/5': {
    marginLeft: '40%',
    marginRight: '40%',
  },
  'my-2/5': {
    marginTop: '40%',
    marginBottom: '40%',
  },
  'm-3/5': {
    margin: '60%',
  },
  'mt-3/5': {
    marginTop: '60%',
  },
  'mb-3/5': {
    marginBottom: '60%',
  },
  'ml-3/5': {
    marginLeft: '60%',
  },
  'mr-3/5': {
    marginRight: '60%',
  },
  'mx-3/5': {
    marginLeft: '60%',
    marginRight: '60%',
  },
  'my-3/5': {
    marginTop: '60%',
    marginBottom: '60%',
  },
  'm-4/5': {
    margin: '80%',
  },
  'mt-4/5': {
    marginTop: '80%',
  },
  'mb-4/5': {
    marginBottom: '80%',
  },
  'ml-4/5': {
    marginLeft: '80%',
  },
  'mr-4/5': {
    marginRight: '80%',
  },
  'mx-4/5': {
    marginLeft: '80%',
    marginRight: '80%',
  },
  'my-4/5': {
    marginTop: '80%',
    marginBottom: '80%',
  },
  'm-a': {
    margin: 'auto',
  },
  'mt-a': {
    marginTop: 'auto',
  },
  'mb-a': {
    marginBottom: 'auto',
  },
  'ml-a': {
    marginLeft: 'auto',
  },
  'mr-a': {
    marginRight: 'auto',
  },
  'mx-a': {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  'my-a': {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  'max-h-0': {
    maxHeight: 0,
  },
  'max-h-1': {
    maxHeight: 16,
  },
  'max-h-2': {
    maxHeight: 32,
  },
  'max-h-3': {
    maxHeight: 48,
  },
  'max-h-4': {
    maxHeight: 64,
  },
  'max-h-5': {
    maxHeight: 80,
  },
  'max-h-6': {
    maxHeight: 96,
  },
  'max-h-8': {
    maxHeight: 128,
  },
  'max-h-10': {
    maxHeight: 160,
  },
  'max-h-12': {
    maxHeight: 192,
  },
  'max-h-16': {
    maxHeight: 256,
  },
  'max-h-24': {
    maxHeight: 384,
  },
  'max-h-32': {
    maxHeight: 512,
  },
  'max-h-48': {
    maxHeight: 768,
  },
  'max-h-64': {
    maxHeight: 1024,
  },
  'max-h-1px': {
    maxHeight: 1,
  },
  'max-h-1/2': {
    maxHeight: '50%',
  },
  'max-h-1/3': {
    maxHeight: '33.33333%',
  },
  'max-h-2/3': {
    maxHeight: '66.66667%',
  },
  'max-h-1/4': {
    maxHeight: '25%',
  },
  'max-h-3/4': {
    maxHeight: '75%',
  },
  'max-h-1/5': {
    maxHeight: '20%',
  },
  'max-h-2/5': {
    maxHeight: '40%',
  },
  'max-h-3/5': {
    maxHeight: '60%',
  },
  'max-h-4/5': {
    maxHeight: '80%',
  },
  'max-h-full': {
    maxHeight: '100%',
  },
  'max-h-a': {
    maxHeight: 'auto',
  },
  'max-w-0': {
    maxWidth: 0,
  },
  'max-w-1': {
    maxWidth: 16,
  },
  'max-w-2': {
    maxWidth: 32,
  },
  'max-w-3': {
    maxWidth: 48,
  },
  'max-w-4': {
    maxWidth: 64,
  },
  'max-w-5': {
    maxWidth: 80,
  },
  'max-w-6': {
    maxWidth: 96,
  },
  'max-w-8': {
    maxWidth: 128,
  },
  'max-w-10': {
    maxWidth: 160,
  },
  'max-w-12': {
    maxWidth: 192,
  },
  'max-w-16': {
    maxWidth: 256,
  },
  'max-w-24': {
    maxWidth: 384,
  },
  'max-w-32': {
    maxWidth: 512,
  },
  'max-w-48': {
    maxWidth: 768,
  },
  'max-w-64': {
    maxWidth: 1024,
  },
  'max-w-1px': {
    maxWidth: 1,
  },
  'max-w-1/2': {
    maxWidth: '50%',
  },
  'max-w-1/3': {
    maxWidth: '33.33333%',
  },
  'max-w-2/3': {
    maxWidth: '66.66667%',
  },
  'max-w-1/4': {
    maxWidth: '25%',
  },
  'max-w-3/4': {
    maxWidth: '75%',
  },
  'max-w-1/5': {
    maxWidth: '20%',
  },
  'max-w-2/5': {
    maxWidth: '40%',
  },
  'max-w-3/5': {
    maxWidth: '60%',
  },
  'max-w-4/5': {
    maxWidth: '80%',
  },
  'max-w-full': {
    maxWidth: '100%',
  },
  'max-w-a': {
    maxWidth: 'auto',
  },
  'min-h-0': {
    minHeight: 0,
  },
  'min-h-1': {
    minHeight: 16,
  },
  'min-h-2': {
    minHeight: 32,
  },
  'min-h-3': {
    minHeight: 48,
  },
  'min-h-4': {
    minHeight: 64,
  },
  'min-h-5': {
    minHeight: 80,
  },
  'min-h-6': {
    minHeight: 96,
  },
  'min-h-8': {
    minHeight: 128,
  },
  'min-h-10': {
    minHeight: 160,
  },
  'min-h-12': {
    minHeight: 192,
  },
  'min-h-16': {
    minHeight: 256,
  },
  'min-h-24': {
    minHeight: 384,
  },
  'min-h-32': {
    minHeight: 512,
  },
  'min-h-48': {
    minHeight: 768,
  },
  'min-h-64': {
    minHeight: 1024,
  },
  'min-h-1px': {
    minHeight: 1,
  },
  'min-h-1/2': {
    minHeight: '50%',
  },
  'min-h-1/3': {
    minHeight: '33.33333%',
  },
  'min-h-2/3': {
    minHeight: '66.66667%',
  },
  'min-h-1/4': {
    minHeight: '25%',
  },
  'min-h-3/4': {
    minHeight: '75%',
  },
  'min-h-1/5': {
    minHeight: '20%',
  },
  'min-h-2/5': {
    minHeight: '40%',
  },
  'min-h-3/5': {
    minHeight: '60%',
  },
  'min-h-4/5': {
    minHeight: '80%',
  },
  'min-h-full': {
    minHeight: '100%',
  },
  'min-h-a': {
    minHeight: 'auto',
  },
  'min-w-0': {
    minWidth: 0,
  },
  'min-w-1': {
    minWidth: 16,
  },
  'min-w-2': {
    minWidth: 32,
  },
  'min-w-3': {
    minWidth: 48,
  },
  'min-w-4': {
    minWidth: 64,
  },
  'min-w-5': {
    minWidth: 80,
  },
  'min-w-6': {
    minWidth: 96,
  },
  'min-w-8': {
    minWidth: 128,
  },
  'min-w-10': {
    minWidth: 160,
  },
  'min-w-12': {
    minWidth: 192,
  },
  'min-w-16': {
    minWidth: 256,
  },
  'min-w-24': {
    minWidth: 384,
  },
  'min-w-32': {
    minWidth: 512,
  },
  'min-w-48': {
    minWidth: 768,
  },
  'min-w-64': {
    minWidth: 1024,
  },
  'min-w-1px': {
    minWidth: 1,
  },
  'min-w-1/2': {
    minWidth: '50%',
  },
  'min-w-1/3': {
    minWidth: '33.33333%',
  },
  'min-w-2/3': {
    minWidth: '66.66667%',
  },
  'min-w-1/4': {
    minWidth: '25%',
  },
  'min-w-3/4': {
    minWidth: '75%',
  },
  'min-w-1/5': {
    minWidth: '20%',
  },
  'min-w-2/5': {
    minWidth: '40%',
  },
  'min-w-3/5': {
    minWidth: '60%',
  },
  'min-w-4/5': {
    minWidth: '80%',
  },
  'min-w-full': {
    minWidth: '100%',
  },
  'min-w-a': {
    minWidth: 'auto',
  },
  'object-contain': {
    objectFit: 'contain',
  },
  'object-cover': {
    objectFit: 'cover',
  },
  'object-fill': {
    objectFit: 'fill',
  },
  'object-none': {
    objectFit: 'none',
  },
  'object-scale-down': {
    objectFit: 'scale-down',
  },
  'p-0': {
    padding: 0,
  },
  'pt-0': {
    paddingTop: 0,
  },
  'pb-0': {
    paddingBottom: 0,
  },
  'pl-0': {
    paddingLeft: 0,
  },
  'pr-0': {
    paddingRight: 0,
  },
  'px-0': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  'py-0': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  'p-1': {
    padding: 4,
  },
  'pt-1': {
    paddingTop: 4,
  },
  'pb-1': {
    paddingBottom: 4,
  },
  'pl-1': {
    paddingLeft: 4,
  },
  'pr-1': {
    paddingRight: 4,
  },
  'px-1': {
    paddingLeft: 4,
    paddingRight: 4,
  },
  'py-1': {
    paddingTop: 4,
    paddingBottom: 4,
  },
  'p-2': {
    padding: 8,
  },
  'pt-2': {
    paddingTop: 8,
  },
  'pb-2': {
    paddingBottom: 8,
  },
  'pl-2': {
    paddingLeft: 8,
  },
  'pr-2': {
    paddingRight: 8,
  },
  'px-2': {
    paddingLeft: 8,
    paddingRight: 8,
  },
  'py-2': {
    paddingTop: 8,
    paddingBottom: 8,
  },
  'p-3': {
    padding: 12,
  },
  'pt-3': {
    paddingTop: 12,
  },
  'pb-3': {
    paddingBottom: 12,
  },
  'pl-3': {
    paddingLeft: 12,
  },
  'pr-3': {
    paddingRight: 12,
  },
  'px-3': {
    paddingLeft: 12,
    paddingRight: 12,
  },
  'py-3': {
    paddingTop: 12,
    paddingBottom: 12,
  },
  'p-4': {
    padding: 16,
  },
  'pt-4': {
    paddingTop: 16,
  },
  'pb-4': {
    paddingBottom: 16,
  },
  'pl-4': {
    paddingLeft: 16,
  },
  'pr-4': {
    paddingRight: 16,
  },
  'px-4': {
    paddingLeft: 16,
    paddingRight: 16,
  },
  'py-4': {
    paddingTop: 16,
    paddingBottom: 16,
  },
  'p-5': {
    padding: 20,
  },
  'pt-5': {
    paddingTop: 20,
  },
  'pb-5': {
    paddingBottom: 20,
  },
  'pl-5': {
    paddingLeft: 20,
  },
  'pr-5': {
    paddingRight: 20,
  },
  'px-5': {
    paddingLeft: 20,
    paddingRight: 20,
  },
  'py-5': {
    paddingTop: 20,
    paddingBottom: 20,
  },
  'p-6': {
    padding: 24,
  },
  'pt-6': {
    paddingTop: 24,
  },
  'pb-6': {
    paddingBottom: 24,
  },
  'pl-6': {
    paddingLeft: 24,
  },
  'pr-6': {
    paddingRight: 24,
  },
  'px-6': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  'py-6': {
    paddingTop: 24,
    paddingBottom: 24,
  },
  'p-8': {
    padding: 32,
  },
  'pt-8': {
    paddingTop: 32,
  },
  'pb-8': {
    paddingBottom: 32,
  },
  'pl-8': {
    paddingLeft: 32,
  },
  'pr-8': {
    paddingRight: 32,
  },
  'px-8': {
    paddingLeft: 32,
    paddingRight: 32,
  },
  'py-8': {
    paddingTop: 32,
    paddingBottom: 32,
  },
  'p-9': {
    padding: 36,
  },
  'pt-9': {
    paddingTop: 36,
  },
  'pb-9': {
    paddingBottom: 36,
  },
  'pl-9': {
    paddingLeft: 36,
  },
  'pr-9': {
    paddingRight: 36,
  },
  'px-9': {
    paddingLeft: 36,
    paddingRight: 36,
  },
  'py-9': {
    paddingTop: 36,
    paddingBottom: 36,
  },
  'p-10': {
    padding: 40,
  },
  'pt-10': {
    paddingTop: 40,
  },
  'pb-10': {
    paddingBottom: 40,
  },
  'pl-10': {
    paddingLeft: 40,
  },
  'pr-10': {
    paddingRight: 40,
  },
  'px-10': {
    paddingLeft: 40,
    paddingRight: 40,
  },
  'py-10': {
    paddingTop: 40,
    paddingBottom: 40,
  },
  'p-12': {
    padding: 48,
  },
  'pt-12': {
    paddingTop: 48,
  },
  'pb-12': {
    paddingBottom: 48,
  },
  'pl-12': {
    paddingLeft: 48,
  },
  'pr-12': {
    paddingRight: 48,
  },
  'px-12': {
    paddingLeft: 48,
    paddingRight: 48,
  },
  'py-12': {
    paddingTop: 48,
    paddingBottom: 48,
  },
  'p-16': {
    padding: 64,
  },
  'pt-16': {
    paddingTop: 64,
  },
  'pb-16': {
    paddingBottom: 64,
  },
  'pl-16': {
    paddingLeft: 64,
  },
  'pr-16': {
    paddingRight: 64,
  },
  'px-16': {
    paddingLeft: 64,
    paddingRight: 64,
  },
  'py-16': {
    paddingTop: 64,
    paddingBottom: 64,
  },
  'p-24': {
    padding: 96,
  },
  'pt-24': {
    paddingTop: 96,
  },
  'pb-24': {
    paddingBottom: 96,
  },
  'pl-24': {
    paddingLeft: 96,
  },
  'pr-24': {
    paddingRight: 96,
  },
  'px-24': {
    paddingLeft: 96,
    paddingRight: 96,
  },
  'py-24': {
    paddingTop: 96,
    paddingBottom: 96,
  },
  'p-32': {
    padding: 128,
  },
  'pt-32': {
    paddingTop: 128,
  },
  'pb-32': {
    paddingBottom: 128,
  },
  'pl-32': {
    paddingLeft: 128,
  },
  'pr-32': {
    paddingRight: 128,
  },
  'px-32': {
    paddingLeft: 128,
    paddingRight: 128,
  },
  'py-32': {
    paddingTop: 128,
    paddingBottom: 128,
  },
  'p-48': {
    padding: 192,
  },
  'pt-48': {
    paddingTop: 192,
  },
  'pb-48': {
    paddingBottom: 192,
  },
  'pl-48': {
    paddingLeft: 192,
  },
  'pr-48': {
    paddingRight: 192,
  },
  'px-48': {
    paddingLeft: 192,
    paddingRight: 192,
  },
  'py-48': {
    paddingTop: 192,
    paddingBottom: 192,
  },
  'p-64': {
    padding: 256,
  },
  'pt-64': {
    paddingTop: 256,
  },
  'pb-64': {
    paddingBottom: 256,
  },
  'pl-64': {
    paddingLeft: 256,
  },
  'pr-64': {
    paddingRight: 256,
  },
  'px-64': {
    paddingLeft: 256,
    paddingRight: 256,
  },
  'py-64': {
    paddingTop: 256,
    paddingBottom: 256,
  },
  'p-1px': {
    padding: 1,
  },
  'pt-1px': {
    paddingTop: 1,
  },
  'pb-1px': {
    paddingBottom: 1,
  },
  'pl-1px': {
    paddingLeft: 1,
  },
  'pr-1px': {
    paddingRight: 1,
  },
  'px-1px': {
    paddingLeft: 1,
    paddingRight: 1,
  },
  'py-1px': {
    paddingTop: 1,
    paddingBottom: 1,
  },
  'p-1/2': {
    padding: '50%',
  },
  'pt-1/2': {
    paddingTop: '50%',
  },
  'pb-1/2': {
    paddingBottom: '50%',
  },
  'pl-1/2': {
    paddingLeft: '50%',
  },
  'pr-1/2': {
    paddingRight: '50%',
  },
  'px-1/2': {
    paddingLeft: '50%',
    paddingRight: '50%',
  },
  'py-1/2': {
    paddingTop: '50%',
    paddingBottom: '50%',
  },
  'p-1/3': {
    padding: '33.33333%',
  },
  'pt-1/3': {
    paddingTop: '33.33333%',
  },
  'pb-1/3': {
    paddingBottom: '33.33333%',
  },
  'pl-1/3': {
    paddingLeft: '33.33333%',
  },
  'pr-1/3': {
    paddingRight: '33.33333%',
  },
  'px-1/3': {
    paddingLeft: '33.33333%',
    paddingRight: '33.33333%',
  },
  'py-1/3': {
    paddingTop: '33.33333%',
    paddingBottom: '33.33333%',
  },
  'p-2/3': {
    padding: '66.66667%',
  },
  'pt-2/3': {
    paddingTop: '66.66667%',
  },
  'pb-2/3': {
    paddingBottom: '66.66667%',
  },
  'pl-2/3': {
    paddingLeft: '66.66667%',
  },
  'pr-2/3': {
    paddingRight: '66.66667%',
  },
  'px-2/3': {
    paddingLeft: '66.66667%',
    paddingRight: '66.66667%',
  },
  'py-2/3': {
    paddingTop: '66.66667%',
    paddingBottom: '66.66667%',
  },
  'p-1/4': {
    padding: '25%',
  },
  'pt-1/4': {
    paddingTop: '25%',
  },
  'pb-1/4': {
    paddingBottom: '25%',
  },
  'pl-1/4': {
    paddingLeft: '25%',
  },
  'pr-1/4': {
    paddingRight: '25%',
  },
  'px-1/4': {
    paddingLeft: '25%',
    paddingRight: '25%',
  },
  'py-1/4': {
    paddingTop: '25%',
    paddingBottom: '25%',
  },
  'p-3/4': {
    padding: '75%',
  },
  'pt-3/4': {
    paddingTop: '75%',
  },
  'pb-3/4': {
    paddingBottom: '75%',
  },
  'pl-3/4': {
    paddingLeft: '75%',
  },
  'pr-3/4': {
    paddingRight: '75%',
  },
  'px-3/4': {
    paddingLeft: '75%',
    paddingRight: '75%',
  },
  'py-3/4': {
    paddingTop: '75%',
    paddingBottom: '75%',
  },
  'p-1/5': {
    padding: '20%',
  },
  'pt-1/5': {
    paddingTop: '20%',
  },
  'pb-1/5': {
    paddingBottom: '20%',
  },
  'pl-1/5': {
    paddingLeft: '20%',
  },
  'pr-1/5': {
    paddingRight: '20%',
  },
  'px-1/5': {
    paddingLeft: '20%',
    paddingRight: '20%',
  },
  'py-1/5': {
    paddingTop: '20%',
    paddingBottom: '20%',
  },
  'p-2/5': {
    padding: '40%',
  },
  'pt-2/5': {
    paddingTop: '40%',
  },
  'pb-2/5': {
    paddingBottom: '40%',
  },
  'pl-2/5': {
    paddingLeft: '40%',
  },
  'pr-2/5': {
    paddingRight: '40%',
  },
  'px-2/5': {
    paddingLeft: '40%',
    paddingRight: '40%',
  },
  'py-2/5': {
    paddingTop: '40%',
    paddingBottom: '40%',
  },
  'p-3/5': {
    padding: '60%',
  },
  'pt-3/5': {
    paddingTop: '60%',
  },
  'pb-3/5': {
    paddingBottom: '60%',
  },
  'pl-3/5': {
    paddingLeft: '60%',
  },
  'pr-3/5': {
    paddingRight: '60%',
  },
  'px-3/5': {
    paddingLeft: '60%',
    paddingRight: '60%',
  },
  'py-3/5': {
    paddingTop: '60%',
    paddingBottom: '60%',
  },
  'p-4/5': {
    padding: '80%',
  },
  'pt-4/5': {
    paddingTop: '80%',
  },
  'pb-4/5': {
    paddingBottom: '80%',
  },
  'pl-4/5': {
    paddingLeft: '80%',
  },
  'pr-4/5': {
    paddingRight: '80%',
  },
  'px-4/5': {
    paddingLeft: '80%',
    paddingRight: '80%',
  },
  'py-4/5': {
    paddingTop: '80%',
    paddingBottom: '80%',
  },
  'p-a': {
    padding: 'auto',
  },
  'pt-a': {
    paddingTop: 'auto',
  },
  'pb-a': {
    paddingBottom: 'auto',
  },
  'pl-a': {
    paddingLeft: 'auto',
  },
  'pr-a': {
    paddingRight: 'auto',
  },
  'px-a': {
    paddingLeft: 'auto',
    paddingRight: 'auto',
  },
  'py-a': {
    paddingTop: 'auto',
    paddingBottom: 'auto',
  },
  absolute: {
    position: 'absolute',
  },
  relative: {
    position: 'relative',
  },
  static: {
    position: 'static',
  },
  fixed: {
    position: 'fixed',
  },
  sticky: {
    position: 'sticky',
  },
  'pin-0': {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  'pin-t-0': {
    top: 0,
  },
  'pin-r-0': {
    right: 0,
  },
  'pin-b-0': {
    bottom: 0,
  },
  'pin-l-0': {
    left: 0,
  },
  'pin-tl-0': {
    top: 0,
    left: 0,
  },
  'pin-tr-0': {
    top: 0,
    right: 0,
  },
  'pin-bl-0': {
    bottom: 0,
    left: 0,
  },
  'pin-br-0': {
    bottom: 0,
    right: 0,
  },
  'pin-x-0': {
    left: 0,
    right: 0,
  },
  'pin-y-0': {
    top: 0,
    bottom: 0,
  },
  'pin-1': {
    top: 4,
    right: 4,
    bottom: 4,
    left: 4,
  },
  'pin-t-1': {
    top: 4,
  },
  'pin-r-1': {
    right: 4,
  },
  'pin-b-1': {
    bottom: 4,
  },
  'pin-l-1': {
    left: 4,
  },
  'pin-tl-1': {
    top: 4,
    left: 4,
  },
  'pin-tr-1': {
    top: 4,
    right: 4,
  },
  'pin-bl-1': {
    bottom: 4,
    left: 4,
  },
  'pin-br-1': {
    bottom: 4,
    right: 4,
  },
  'pin-x-1': {
    left: 4,
    right: 4,
  },
  'pin-y-1': {
    top: 4,
    bottom: 4,
  },
  'pin-2': {
    top: 8,
    right: 8,
    bottom: 8,
    left: 8,
  },
  'pin-t-2': {
    top: 8,
  },
  'pin-r-2': {
    right: 8,
  },
  'pin-b-2': {
    bottom: 8,
  },
  'pin-l-2': {
    left: 8,
  },
  'pin-tl-2': {
    top: 8,
    left: 8,
  },
  'pin-tr-2': {
    top: 8,
    right: 8,
  },
  'pin-bl-2': {
    bottom: 8,
    left: 8,
  },
  'pin-br-2': {
    bottom: 8,
    right: 8,
  },
  'pin-x-2': {
    left: 8,
    right: 8,
  },
  'pin-y-2': {
    top: 8,
    bottom: 8,
  },
  'pin-3': {
    top: 12,
    right: 12,
    bottom: 12,
    left: 12,
  },
  'pin-t-3': {
    top: 12,
  },
  'pin-r-3': {
    right: 12,
  },
  'pin-b-3': {
    bottom: 12,
  },
  'pin-l-3': {
    left: 12,
  },
  'pin-tl-3': {
    top: 12,
    left: 12,
  },
  'pin-tr-3': {
    top: 12,
    right: 12,
  },
  'pin-bl-3': {
    bottom: 12,
    left: 12,
  },
  'pin-br-3': {
    bottom: 12,
    right: 12,
  },
  'pin-x-3': {
    left: 12,
    right: 12,
  },
  'pin-y-3': {
    top: 12,
    bottom: 12,
  },
  'pin-4': {
    top: 16,
    right: 16,
    bottom: 16,
    left: 16,
  },
  'pin-t-4': {
    top: 16,
  },
  'pin-r-4': {
    right: 16,
  },
  'pin-b-4': {
    bottom: 16,
  },
  'pin-l-4': {
    left: 16,
  },
  'pin-tl-4': {
    top: 16,
    left: 16,
  },
  'pin-tr-4': {
    top: 16,
    right: 16,
  },
  'pin-bl-4': {
    bottom: 16,
    left: 16,
  },
  'pin-br-4': {
    bottom: 16,
    right: 16,
  },
  'pin-x-4': {
    left: 16,
    right: 16,
  },
  'pin-y-4': {
    top: 16,
    bottom: 16,
  },
  'pin-5': {
    top: 20,
    right: 20,
    bottom: 20,
    left: 20,
  },
  'pin-t-5': {
    top: 20,
  },
  'pin-r-5': {
    right: 20,
  },
  'pin-b-5': {
    bottom: 20,
  },
  'pin-l-5': {
    left: 20,
  },
  'pin-tl-5': {
    top: 20,
    left: 20,
  },
  'pin-tr-5': {
    top: 20,
    right: 20,
  },
  'pin-bl-5': {
    bottom: 20,
    left: 20,
  },
  'pin-br-5': {
    bottom: 20,
    right: 20,
  },
  'pin-x-5': {
    left: 20,
    right: 20,
  },
  'pin-y-5': {
    top: 20,
    bottom: 20,
  },
  'pin-6': {
    top: 24,
    right: 24,
    bottom: 24,
    left: 24,
  },
  'pin-t-6': {
    top: 24,
  },
  'pin-r-6': {
    right: 24,
  },
  'pin-b-6': {
    bottom: 24,
  },
  'pin-l-6': {
    left: 24,
  },
  'pin-tl-6': {
    top: 24,
    left: 24,
  },
  'pin-tr-6': {
    top: 24,
    right: 24,
  },
  'pin-bl-6': {
    bottom: 24,
    left: 24,
  },
  'pin-br-6': {
    bottom: 24,
    right: 24,
  },
  'pin-x-6': {
    left: 24,
    right: 24,
  },
  'pin-y-6': {
    top: 24,
    bottom: 24,
  },
  'pin-8': {
    top: 32,
    right: 32,
    bottom: 32,
    left: 32,
  },
  'pin-t-8': {
    top: 32,
  },
  'pin-r-8': {
    right: 32,
  },
  'pin-b-8': {
    bottom: 32,
  },
  'pin-l-8': {
    left: 32,
  },
  'pin-tl-8': {
    top: 32,
    left: 32,
  },
  'pin-tr-8': {
    top: 32,
    right: 32,
  },
  'pin-bl-8': {
    bottom: 32,
    left: 32,
  },
  'pin-br-8': {
    bottom: 32,
    right: 32,
  },
  'pin-x-8': {
    left: 32,
    right: 32,
  },
  'pin-y-8': {
    top: 32,
    bottom: 32,
  },
  'pin-10': {
    top: 40,
    right: 40,
    bottom: 40,
    left: 40,
  },
  'pin-t-10': {
    top: 40,
  },
  'pin-r-10': {
    right: 40,
  },
  'pin-b-10': {
    bottom: 40,
  },
  'pin-l-10': {
    left: 40,
  },
  'pin-tl-10': {
    top: 40,
    left: 40,
  },
  'pin-tr-10': {
    top: 40,
    right: 40,
  },
  'pin-bl-10': {
    bottom: 40,
    left: 40,
  },
  'pin-br-10': {
    bottom: 40,
    right: 40,
  },
  'pin-x-10': {
    left: 40,
    right: 40,
  },
  'pin-y-10': {
    top: 40,
    bottom: 40,
  },
  'pin-12': {
    top: 48,
    right: 48,
    bottom: 48,
    left: 48,
  },
  'pin-t-12': {
    top: 48,
  },
  'pin-r-12': {
    right: 48,
  },
  'pin-b-12': {
    bottom: 48,
  },
  'pin-l-12': {
    left: 48,
  },
  'pin-tl-12': {
    top: 48,
    left: 48,
  },
  'pin-tr-12': {
    top: 48,
    right: 48,
  },
  'pin-bl-12': {
    bottom: 48,
    left: 48,
  },
  'pin-br-12': {
    bottom: 48,
    right: 48,
  },
  'pin-x-12': {
    left: 48,
    right: 48,
  },
  'pin-y-12': {
    top: 48,
    bottom: 48,
  },
  'pin-16': {
    top: 64,
    right: 64,
    bottom: 64,
    left: 64,
  },
  'pin-t-16': {
    top: 64,
  },
  'pin-r-16': {
    right: 64,
  },
  'pin-b-16': {
    bottom: 64,
  },
  'pin-l-16': {
    left: 64,
  },
  'pin-tl-16': {
    top: 64,
    left: 64,
  },
  'pin-tr-16': {
    top: 64,
    right: 64,
  },
  'pin-bl-16': {
    bottom: 64,
    left: 64,
  },
  'pin-br-16': {
    bottom: 64,
    right: 64,
  },
  'pin-x-16': {
    left: 64,
    right: 64,
  },
  'pin-y-16': {
    top: 64,
    bottom: 64,
  },
  'pin-24': {
    top: 96,
    right: 96,
    bottom: 96,
    left: 96,
  },
  'pin-t-24': {
    top: 96,
  },
  'pin-r-24': {
    right: 96,
  },
  'pin-b-24': {
    bottom: 96,
  },
  'pin-l-24': {
    left: 96,
  },
  'pin-tl-24': {
    top: 96,
    left: 96,
  },
  'pin-tr-24': {
    top: 96,
    right: 96,
  },
  'pin-bl-24': {
    bottom: 96,
    left: 96,
  },
  'pin-br-24': {
    bottom: 96,
    right: 96,
  },
  'pin-x-24': {
    left: 96,
    right: 96,
  },
  'pin-y-24': {
    top: 96,
    bottom: 96,
  },
  'pin-32': {
    top: 128,
    right: 128,
    bottom: 128,
    left: 128,
  },
  'pin-t-32': {
    top: 128,
  },
  'pin-r-32': {
    right: 128,
  },
  'pin-b-32': {
    bottom: 128,
  },
  'pin-l-32': {
    left: 128,
  },
  'pin-tl-32': {
    top: 128,
    left: 128,
  },
  'pin-tr-32': {
    top: 128,
    right: 128,
  },
  'pin-bl-32': {
    bottom: 128,
    left: 128,
  },
  'pin-br-32': {
    bottom: 128,
    right: 128,
  },
  'pin-x-32': {
    left: 128,
    right: 128,
  },
  'pin-y-32': {
    top: 128,
    bottom: 128,
  },
  'pin-48': {
    top: 192,
    right: 192,
    bottom: 192,
    left: 192,
  },
  'pin-t-48': {
    top: 192,
  },
  'pin-r-48': {
    right: 192,
  },
  'pin-b-48': {
    bottom: 192,
  },
  'pin-l-48': {
    left: 192,
  },
  'pin-tl-48': {
    top: 192,
    left: 192,
  },
  'pin-tr-48': {
    top: 192,
    right: 192,
  },
  'pin-bl-48': {
    bottom: 192,
    left: 192,
  },
  'pin-br-48': {
    bottom: 192,
    right: 192,
  },
  'pin-x-48': {
    left: 192,
    right: 192,
  },
  'pin-y-48': {
    top: 192,
    bottom: 192,
  },
  'pin-64': {
    top: 256,
    right: 256,
    bottom: 256,
    left: 256,
  },
  'pin-t-64': {
    top: 256,
  },
  'pin-r-64': {
    right: 256,
  },
  'pin-b-64': {
    bottom: 256,
  },
  'pin-l-64': {
    left: 256,
  },
  'pin-tl-64': {
    top: 256,
    left: 256,
  },
  'pin-tr-64': {
    top: 256,
    right: 256,
  },
  'pin-bl-64': {
    bottom: 256,
    left: 256,
  },
  'pin-br-64': {
    bottom: 256,
    right: 256,
  },
  'pin-x-64': {
    left: 256,
    right: 256,
  },
  'pin-y-64': {
    top: 256,
    bottom: 256,
  },
  '-pin-1': {
    top: -4,
    right: -4,
    bottom: -4,
    left: -4,
  },
  '-pin-t-1': {
    top: -4,
  },
  '-pin-r-1': {
    right: -4,
  },
  '-pin-b-1': {
    bottom: -4,
  },
  '-pin-l-1': {
    left: -4,
  },
  '-pin-tl-1': {
    top: -4,
    left: -4,
  },
  '-pin-tr-1': {
    top: -4,
    right: -4,
  },
  '-pin-bl-1': {
    bottom: -4,
    left: -4,
  },
  '-pin-br-1': {
    bottom: -4,
    right: -4,
  },
  '-pin-x-1': {
    left: -4,
    right: -4,
  },
  '-pin-y-1': {
    top: -4,
    bottom: -4,
  },
  '-pin-2': {
    top: -8,
    right: -8,
    bottom: -8,
    left: -8,
  },
  '-pin-t-2': {
    top: -8,
  },
  '-pin-r-2': {
    right: -8,
  },
  '-pin-b-2': {
    bottom: -8,
  },
  '-pin-l-2': {
    left: -8,
  },
  '-pin-tl-2': {
    top: -8,
    left: -8,
  },
  '-pin-tr-2': {
    top: -8,
    right: -8,
  },
  '-pin-bl-2': {
    bottom: -8,
    left: -8,
  },
  '-pin-br-2': {
    bottom: -8,
    right: -8,
  },
  '-pin-x-2': {
    left: -8,
    right: -8,
  },
  '-pin-y-2': {
    top: -8,
    bottom: -8,
  },
  '-pin-3': {
    top: -12,
    right: -12,
    bottom: -12,
    left: -12,
  },
  '-pin-t-3': {
    top: -12,
  },
  '-pin-r-3': {
    right: -12,
  },
  '-pin-b-3': {
    bottom: -12,
  },
  '-pin-l-3': {
    left: -12,
  },
  '-pin-tl-3': {
    top: -12,
    left: -12,
  },
  '-pin-tr-3': {
    top: -12,
    right: -12,
  },
  '-pin-bl-3': {
    bottom: -12,
    left: -12,
  },
  '-pin-br-3': {
    bottom: -12,
    right: -12,
  },
  '-pin-x-3': {
    left: -12,
    right: -12,
  },
  '-pin-y-3': {
    top: -12,
    bottom: -12,
  },
  '-pin-4': {
    top: -16,
    right: -16,
    bottom: -16,
    left: -16,
  },
  '-pin-t-4': {
    top: -16,
  },
  '-pin-r-4': {
    right: -16,
  },
  '-pin-b-4': {
    bottom: -16,
  },
  '-pin-l-4': {
    left: -16,
  },
  '-pin-tl-4': {
    top: -16,
    left: -16,
  },
  '-pin-tr-4': {
    top: -16,
    right: -16,
  },
  '-pin-bl-4': {
    bottom: -16,
    left: -16,
  },
  '-pin-br-4': {
    bottom: -16,
    right: -16,
  },
  '-pin-x-4': {
    left: -16,
    right: -16,
  },
  '-pin-y-4': {
    top: -16,
    bottom: -16,
  },
  '-pin-5': {
    top: -20,
    right: -20,
    bottom: -20,
    left: -20,
  },
  '-pin-t-5': {
    top: -20,
  },
  '-pin-r-5': {
    right: -20,
  },
  '-pin-b-5': {
    bottom: -20,
  },
  '-pin-l-5': {
    left: -20,
  },
  '-pin-tl-5': {
    top: -20,
    left: -20,
  },
  '-pin-tr-5': {
    top: -20,
    right: -20,
  },
  '-pin-bl-5': {
    bottom: -20,
    left: -20,
  },
  '-pin-br-5': {
    bottom: -20,
    right: -20,
  },
  '-pin-x-5': {
    left: -20,
    right: -20,
  },
  '-pin-y-5': {
    top: -20,
    bottom: -20,
  },
  '-pin-6': {
    top: -24,
    right: -24,
    bottom: -24,
    left: -24,
  },
  '-pin-t-6': {
    top: -24,
  },
  '-pin-r-6': {
    right: -24,
  },
  '-pin-b-6': {
    bottom: -24,
  },
  '-pin-l-6': {
    left: -24,
  },
  '-pin-tl-6': {
    top: -24,
    left: -24,
  },
  '-pin-tr-6': {
    top: -24,
    right: -24,
  },
  '-pin-bl-6': {
    bottom: -24,
    left: -24,
  },
  '-pin-br-6': {
    bottom: -24,
    right: -24,
  },
  '-pin-x-6': {
    left: -24,
    right: -24,
  },
  '-pin-y-6': {
    top: -24,
    bottom: -24,
  },
  '-pin-8': {
    top: -32,
    right: -32,
    bottom: -32,
    left: -32,
  },
  '-pin-t-8': {
    top: -32,
  },
  '-pin-r-8': {
    right: -32,
  },
  '-pin-b-8': {
    bottom: -32,
  },
  '-pin-l-8': {
    left: -32,
  },
  '-pin-tl-8': {
    top: -32,
    left: -32,
  },
  '-pin-tr-8': {
    top: -32,
    right: -32,
  },
  '-pin-bl-8': {
    bottom: -32,
    left: -32,
  },
  '-pin-br-8': {
    bottom: -32,
    right: -32,
  },
  '-pin-x-8': {
    left: -32,
    right: -32,
  },
  '-pin-y-8': {
    top: -32,
    bottom: -32,
  },
  '-pin-10': {
    top: -40,
    right: -40,
    bottom: -40,
    left: -40,
  },
  '-pin-t-10': {
    top: -40,
  },
  '-pin-r-10': {
    right: -40,
  },
  '-pin-b-10': {
    bottom: -40,
  },
  '-pin-l-10': {
    left: -40,
  },
  '-pin-tl-10': {
    top: -40,
    left: -40,
  },
  '-pin-tr-10': {
    top: -40,
    right: -40,
  },
  '-pin-bl-10': {
    bottom: -40,
    left: -40,
  },
  '-pin-br-10': {
    bottom: -40,
    right: -40,
  },
  '-pin-x-10': {
    left: -40,
    right: -40,
  },
  '-pin-y-10': {
    top: -40,
    bottom: -40,
  },
  '-pin-12': {
    top: -48,
    right: -48,
    bottom: -48,
    left: -48,
  },
  '-pin-t-12': {
    top: -48,
  },
  '-pin-r-12': {
    right: -48,
  },
  '-pin-b-12': {
    bottom: -48,
  },
  '-pin-l-12': {
    left: -48,
  },
  '-pin-tl-12': {
    top: -48,
    left: -48,
  },
  '-pin-tr-12': {
    top: -48,
    right: -48,
  },
  '-pin-bl-12': {
    bottom: -48,
    left: -48,
  },
  '-pin-br-12': {
    bottom: -48,
    right: -48,
  },
  '-pin-x-12': {
    left: -48,
    right: -48,
  },
  '-pin-y-12': {
    top: -48,
    bottom: -48,
  },
  '-pin-16': {
    top: -64,
    right: -64,
    bottom: -64,
    left: -64,
  },
  '-pin-t-16': {
    top: -64,
  },
  '-pin-r-16': {
    right: -64,
  },
  '-pin-b-16': {
    bottom: -64,
  },
  '-pin-l-16': {
    left: -64,
  },
  '-pin-tl-16': {
    top: -64,
    left: -64,
  },
  '-pin-tr-16': {
    top: -64,
    right: -64,
  },
  '-pin-bl-16': {
    bottom: -64,
    left: -64,
  },
  '-pin-br-16': {
    bottom: -64,
    right: -64,
  },
  '-pin-x-16': {
    left: -64,
    right: -64,
  },
  '-pin-y-16': {
    top: -64,
    bottom: -64,
  },
  '-pin-24': {
    top: -96,
    right: -96,
    bottom: -96,
    left: -96,
  },
  '-pin-t-24': {
    top: -96,
  },
  '-pin-r-24': {
    right: -96,
  },
  '-pin-b-24': {
    bottom: -96,
  },
  '-pin-l-24': {
    left: -96,
  },
  '-pin-tl-24': {
    top: -96,
    left: -96,
  },
  '-pin-tr-24': {
    top: -96,
    right: -96,
  },
  '-pin-bl-24': {
    bottom: -96,
    left: -96,
  },
  '-pin-br-24': {
    bottom: -96,
    right: -96,
  },
  '-pin-x-24': {
    left: -96,
    right: -96,
  },
  '-pin-y-24': {
    top: -96,
    bottom: -96,
  },
  '-pin-32': {
    top: -128,
    right: -128,
    bottom: -128,
    left: -128,
  },
  '-pin-t-32': {
    top: -128,
  },
  '-pin-r-32': {
    right: -128,
  },
  '-pin-b-32': {
    bottom: -128,
  },
  '-pin-l-32': {
    left: -128,
  },
  '-pin-tl-32': {
    top: -128,
    left: -128,
  },
  '-pin-tr-32': {
    top: -128,
    right: -128,
  },
  '-pin-bl-32': {
    bottom: -128,
    left: -128,
  },
  '-pin-br-32': {
    bottom: -128,
    right: -128,
  },
  '-pin-x-32': {
    left: -128,
    right: -128,
  },
  '-pin-y-32': {
    top: -128,
    bottom: -128,
  },
  '-pin-48': {
    top: -192,
    right: -192,
    bottom: -192,
    left: -192,
  },
  '-pin-t-48': {
    top: -192,
  },
  '-pin-r-48': {
    right: -192,
  },
  '-pin-b-48': {
    bottom: -192,
  },
  '-pin-l-48': {
    left: -192,
  },
  '-pin-tl-48': {
    top: -192,
    left: -192,
  },
  '-pin-tr-48': {
    top: -192,
    right: -192,
  },
  '-pin-bl-48': {
    bottom: -192,
    left: -192,
  },
  '-pin-br-48': {
    bottom: -192,
    right: -192,
  },
  '-pin-x-48': {
    left: -192,
    right: -192,
  },
  '-pin-y-48': {
    top: -192,
    bottom: -192,
  },
  '-pin-64': {
    top: -256,
    right: -256,
    bottom: -256,
    left: -256,
  },
  '-pin-t-64': {
    top: -256,
  },
  '-pin-r-64': {
    right: -256,
  },
  '-pin-b-64': {
    bottom: -256,
  },
  '-pin-l-64': {
    left: -256,
  },
  '-pin-tl-64': {
    top: -256,
    left: -256,
  },
  '-pin-tr-64': {
    top: -256,
    right: -256,
  },
  '-pin-bl-64': {
    bottom: -256,
    left: -256,
  },
  '-pin-br-64': {
    bottom: -256,
    right: -256,
  },
  '-pin-x-64': {
    left: -256,
    right: -256,
  },
  '-pin-y-64': {
    top: -256,
    bottom: -256,
  },
  'shadow-none': {
    boxShadow: 'none',
  },
  'shadow-xs': {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  },
  'shadow-sm': {
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  },
  'shadow-md': {
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  },
  'shadow-lg': {
    boxShadow:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  },
  'shadow-xl': {
    boxShadow:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  },
  'shadow-2xl': {
    boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  },
  'text-left': {
    textAlign: 'left',
  },
  'text-right': {
    textAlign: 'right',
  },
  'text-center': {
    textAlign: 'center',
  },
  'text-transparent': {
    color: 'transparent',
  },
  'text-gray-50': {
    color: '#FAFAFA',
  },
  'text-gray-100': {
    color: '#F4F4F5',
  },
  'text-gray-200': {
    color: '#E4E4E7',
  },
  'text-gray-300': {
    color: '#D4D4D8',
  },
  'text-gray-400': {
    color: '#A1A1AA',
  },
  'text-gray-500': {
    color: '#71717A',
  },
  'text-gray-600': {
    color: '#52525B',
  },
  'text-gray-700': {
    color: '#3F3F46',
  },
  'text-gray-800': {
    color: '#27272A',
  },
  'text-gray-900': {
    color: '#18181B',
  },
  'text-rose-50': {
    color: '#fff1f2',
  },
  'text-rose-100': {
    color: '#ffe4e6',
  },
  'text-rose-200': {
    color: '#fecdd3',
  },
  'text-rose-300': {
    color: '#fda4af',
  },
  'text-rose-400': {
    color: '#fb7185',
  },
  'text-rose-500': {
    color: '#f43f5e',
  },
  'text-rose-600': {
    color: '#e11d48',
  },
  'text-rose-700': {
    color: '#be123c',
  },
  'text-rose-800': {
    color: '#9f1239',
  },
  'text-rose-900': {
    color: '#881337',
  },
  'text-pink-50': {
    color: '#fdf2f8',
  },
  'text-pink-100': {
    color: '#fce7f3',
  },
  'text-pink-200': {
    color: '#fbcfe8',
  },
  'text-pink-300': {
    color: '#f9a8d4',
  },
  'text-pink-400': {
    color: '#f472b6',
  },
  'text-pink-500': {
    color: '#ec4899',
  },
  'text-pink-600': {
    color: '#db2777',
  },
  'text-pink-700': {
    color: '#be185d',
  },
  'text-pink-800': {
    color: '#9d174d',
  },
  'text-pink-900': {
    color: '#831843',
  },
  'text-fuchsia-50': {
    color: '#fdf4ff',
  },
  'text-fuchsia-100': {
    color: '#fae8ff',
  },
  'text-fuchsia-200': {
    color: '#f5d0fe',
  },
  'text-fuchsia-300': {
    color: '#f0abfc',
  },
  'text-fuchsia-400': {
    color: '#e879f9',
  },
  'text-fuchsia-500': {
    color: '#d946ef',
  },
  'text-fuchsia-600': {
    color: '#c026d3',
  },
  'text-fuchsia-700': {
    color: '#a21caf',
  },
  'text-fuchsia-800': {
    color: '#86198f',
  },
  'text-fuchsia-900': {
    color: '#701a75',
  },
  'text-purple-50': {
    color: '#faf5ff',
  },
  'text-purple-100': {
    color: '#f3e8ff',
  },
  'text-purple-200': {
    color: '#e9d5ff',
  },
  'text-purple-300': {
    color: '#d8b4fe',
  },
  'text-purple-400': {
    color: '#c084fc',
  },
  'text-purple-500': {
    color: '#a855f7',
  },
  'text-purple-600': {
    color: '#9333ea',
  },
  'text-purple-700': {
    color: '#7e22ce',
  },
  'text-purple-800': {
    color: '#6b21a8',
  },
  'text-purple-900': {
    color: '#581c87',
  },
  'text-violet-50': {
    color: '#f5f3ff',
  },
  'text-violet-100': {
    color: '#ede9fe',
  },
  'text-violet-200': {
    color: '#ddd6fe',
  },
  'text-violet-300': {
    color: '#c4b5fd',
  },
  'text-violet-400': {
    color: '#a78bfa',
  },
  'text-violet-500': {
    color: '#8b5cf6',
  },
  'text-violet-600': {
    color: '#7c3aed',
  },
  'text-violet-700': {
    color: '#6d28d9',
  },
  'text-violet-800': {
    color: '#5b21b6',
  },
  'text-violet-900': {
    color: '#4c1d95',
  },
  'text-indigo-50': {
    color: '#eef2ff',
  },
  'text-indigo-100': {
    color: '#e0e7ff',
  },
  'text-indigo-200': {
    color: '#c7d2fe',
  },
  'text-indigo-300': {
    color: '#a5b4fc',
  },
  'text-indigo-400': {
    color: '#818cf8',
  },
  'text-indigo-500': {
    color: '#6366f1',
  },
  'text-indigo-600': {
    color: '#4f46e5',
  },
  'text-indigo-700': {
    color: '#4338ca',
  },
  'text-indigo-800': {
    color: '#3730a3',
  },
  'text-indigo-900': {
    color: '#312e81',
  },
  'text-blue-50': {
    color: '#eff6ff',
  },
  'text-blue-100': {
    color: '#dbeafe',
  },
  'text-blue-200': {
    color: '#bfdbfe',
  },
  'text-blue-300': {
    color: '#93c5fd',
  },
  'text-blue-400': {
    color: '#60a5fa',
  },
  'text-blue-500': {
    color: '#3b82f6',
  },
  'text-blue-600': {
    color: '#2563eb',
  },
  'text-blue-700': {
    color: '#1d4ed8',
  },
  'text-blue-800': {
    color: '#1e40af',
  },
  'text-blue-900': {
    color: '#1e3a8a',
  },
  'text-lightBlue-50': {
    color: '#f0f9ff',
  },
  'text-lightBlue-100': {
    color: '#e0f2fe',
  },
  'text-lightBlue-200': {
    color: '#bae6fd',
  },
  'text-lightBlue-300': {
    color: '#7dd3fc',
  },
  'text-lightBlue-400': {
    color: '#38bdf8',
  },
  'text-lightBlue-500': {
    color: '#0ea5e9',
  },
  'text-lightBlue-600': {
    color: '#0284c7',
  },
  'text-lightBlue-700': {
    color: '#0369a1',
  },
  'text-lightBlue-800': {
    color: '#075985',
  },
  'text-lightBlue-900': {
    color: '#0c4a6e',
  },
  'text-cyan-50': {
    color: '#ecfeff',
  },
  'text-cyan-100': {
    color: '#cffafe',
  },
  'text-cyan-200': {
    color: '#a5f3fc',
  },
  'text-cyan-300': {
    color: '#67e8f9',
  },
  'text-cyan-400': {
    color: '#22d3ee',
  },
  'text-cyan-500': {
    color: '#06b6d4',
  },
  'text-cyan-600': {
    color: '#0891b2',
  },
  'text-cyan-700': {
    color: '#0e7490',
  },
  'text-cyan-800': {
    color: '#155e75',
  },
  'text-cyan-900': {
    color: '#164e63',
  },
  'text-teal-50': {
    color: '#f0fdfa',
  },
  'text-teal-100': {
    color: '#ccfbf1',
  },
  'text-teal-200': {
    color: '#99f6e4',
  },
  'text-teal-300': {
    color: '#5eead4',
  },
  'text-teal-400': {
    color: '#2dd4bf',
  },
  'text-teal-500': {
    color: '#14b8a6',
  },
  'text-teal-600': {
    color: '#0d9488',
  },
  'text-teal-700': {
    color: '#0f766e',
  },
  'text-teal-800': {
    color: '#115e59',
  },
  'text-teal-900': {
    color: '#134e4a',
  },
  'text-emerald-50': {
    color: '#ecfdf5',
  },
  'text-emerald-100': {
    color: '#d1fae5',
  },
  'text-emerald-200': {
    color: '#a7f3d0',
  },
  'text-emerald-300': {
    color: '#6ee7b7',
  },
  'text-emerald-400': {
    color: '#34d399',
  },
  'text-emerald-500': {
    color: '#10b981',
  },
  'text-emerald-600': {
    color: '#059669',
  },
  'text-emerald-700': {
    color: '#047857',
  },
  'text-emerald-800': {
    color: '#065f46',
  },
  'text-emerald-900': {
    color: '#064e3b',
  },
  'text-green-50': {
    color: '#f0fdf4',
  },
  'text-green-100': {
    color: '#dcfce7',
  },
  'text-green-200': {
    color: '#bbf7d0',
  },
  'text-green-300': {
    color: '#86efac',
  },
  'text-green-400': {
    color: '#4ade80',
  },
  'text-green-500': {
    color: '#22c55e',
  },
  'text-green-600': {
    color: '#16a34a',
  },
  'text-green-700': {
    color: '#15803d',
  },
  'text-green-800': {
    color: '#166534',
  },
  'text-green-900': {
    color: '#14532d',
  },
  'text-lime-50': {
    color: '#f7fee7',
  },
  'text-lime-100': {
    color: '#ecfccb',
  },
  'text-lime-200': {
    color: '#d9f99d',
  },
  'text-lime-300': {
    color: '#bef264',
  },
  'text-lime-400': {
    color: '#a3e635',
  },
  'text-lime-500': {
    color: '#84cc16',
  },
  'text-lime-600': {
    color: '#65a30d',
  },
  'text-lime-700': {
    color: '#4d7c0f',
  },
  'text-lime-800': {
    color: '#3f6212',
  },
  'text-lime-900': {
    color: '#365314',
  },
  'text-yellow-50': {
    color: '#fefce8',
  },
  'text-yellow-100': {
    color: '#fef9c3',
  },
  'text-yellow-200': {
    color: '#fef08a',
  },
  'text-yellow-300': {
    color: '#fde047',
  },
  'text-yellow-400': {
    color: '#facc15',
  },
  'text-yellow-500': {
    color: '#eab308',
  },
  'text-yellow-600': {
    color: '#ca8a04',
  },
  'text-yellow-700': {
    color: '#a16207',
  },
  'text-yellow-800': {
    color: '#854d0e',
  },
  'text-yellow-900': {
    color: '#713f12',
  },
  'text-amber-50': {
    color: '#fffbeb',
  },
  'text-amber-100': {
    color: '#fef3c7',
  },
  'text-amber-200': {
    color: '#fde68a',
  },
  'text-amber-300': {
    color: '#fcd34d',
  },
  'text-amber-400': {
    color: '#fbbf24',
  },
  'text-amber-500': {
    color: '#f59e0b',
  },
  'text-amber-600': {
    color: '#d97706',
  },
  'text-amber-700': {
    color: '#b45309',
  },
  'text-amber-800': {
    color: '#92400e',
  },
  'text-amber-900': {
    color: '#78350f',
  },
  'text-orange-50': {
    color: '#fff7ed',
  },
  'text-orange-100': {
    color: '#ffedd5',
  },
  'text-orange-200': {
    color: '#fed7aa',
  },
  'text-orange-300': {
    color: '#fdba74',
  },
  'text-orange-400': {
    color: '#fb923c',
  },
  'text-orange-500': {
    color: '#f97316',
  },
  'text-orange-600': {
    color: '#ea580c',
  },
  'text-orange-700': {
    color: '#c2410c',
  },
  'text-orange-800': {
    color: '#9a3412',
  },
  'text-orange-900': {
    color: '#7c2d12',
  },
  'text-red-50': {
    color: '#fef2f2',
  },
  'text-red-100': {
    color: '#fee2e2',
  },
  'text-red-200': {
    color: '#fecaca',
  },
  'text-red-300': {
    color: '#fca5a5',
  },
  'text-red-400': {
    color: '#f87171',
  },
  'text-red-500': {
    color: '#ef4444',
  },
  'text-red-600': {
    color: '#dc2626',
  },
  'text-red-700': {
    color: '#b91c1c',
  },
  'text-red-800': {
    color: '#991b1b',
  },
  'text-red-900': {
    color: '#7f1d1d',
  },
  'no-decoration': {
    textDecorationLine: 'none',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  'line-through': {
    textDecorationLine: 'line-through',
  },
  'normal-case': {
    textTransform: 'none',
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  lowercase: {
    textTransform: 'lowercase',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  'align-baseline': {
    verticalAlign: 'baseline',
  },
  'align-top': {
    verticalAlign: 'top',
  },
  'align-middle': {
    verticalAlign: 'middle',
  },
  'align-bottom': {
    verticalAlign: 'bottom',
  },
  'align-text-top': {
    verticalAlign: 'text-top',
  },
  'align-text-bottom': {
    verticalAlign: 'text-bottom',
  },
  'w-0': {
    width: 0,
  },
  'w-1': {
    width: 16,
  },
  'w-2': {
    width: 32,
  },
  'w-3': {
    width: 48,
  },
  'w-4': {
    width: 64,
  },
  'w-5': {
    width: 80,
  },
  'w-6': {
    width: 96,
  },
  'w-8': {
    width: 128,
  },
  'w-10': {
    width: 160,
  },
  'w-12': {
    width: 192,
  },
  'w-16': {
    width: 256,
  },
  'w-24': {
    width: 384,
  },
  'w-32': {
    width: 512,
  },
  'w-48': {
    width: 768,
  },
  'w-64': {
    width: 1024,
  },
  'w-1px': {
    width: 1,
  },
  'w-1/2': {
    width: '50%',
  },
  'w-1/3': {
    width: '33.33333%',
  },
  'w-2/3': {
    width: '66.66667%',
  },
  'w-1/4': {
    width: '25%',
  },
  'w-3/4': {
    width: '75%',
  },
  'w-1/5': {
    width: '20%',
  },
  'w-2/5': {
    width: '40%',
  },
  'w-3/5': {
    width: '60%',
  },
  'w-4/5': {
    width: '80%',
  },
  'w-full': {
    width: '100%',
  },
  'w-a': {
    width: 'auto',
  },
  'z-0': {
    zIndex: 0,
  },
  'z-1': {
    zIndex: 1,
  },
  'z-2': {
    zIndex: 2,
  },
  'z-3': {
    zIndex: 3,
  },
  'z-4': {
    zIndex: 4,
  },
  'z-5': {
    zIndex: 5,
  },
  'show-box': {
    borderWidth: 1,
    borderColor: 'red',
    borderStyle: 'solid',
  },
})

function s(utilities, overrides = {}) {
  if (typeof utilities !== 'string') {
    throw new TypeError(
      `TypeError [headwind]: expected utilities to be a 'string', got '${typeof utilities}' instead`
    )
  }

  if (typeof overrides !== 'object') {
    throw new TypeError(
      `TypeError [headwind]: expected overrides to be an 'object', got '${typeof overrides}' instead`
    )
  }

  const styles = utilities
    .split(' ')
    .reduce((acc, utility) => Object.assign(acc, headwindUtilities[utility] || {}), {})

  return Object.assign(styles, overrides)
}

export { s }
