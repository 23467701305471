import Modal from 'react-modal'
import React, { useState } from 'react'
import { s, smaller, styled } from 'styled'
import { ASYNC_STATUS } from '../../constants'
import { XCircleIcon as XCircleIconBase } from './XCircleIcon'

import { EmailForm } from './EmailForm'

const Content = styled.div(
  s(
    'p-12 pt-10 m-a flex justify-between h-full show-box border-gray-400 rounded-md relative flex-column',
    {
      maxWidth: 500,
      background: '#fff',
      maxHeight: '80vh',
      overflowY: 'scroll',
    }
  ),
  smaller('sm')(s('p-4 py-10 h-full', { maxWidth: '80%' }))
)

const XCircleIcon = styled(XCircleIconBase)(
  s('absolute pin-tr-4 w-2 h-2', {
    cursor: 'pointer',
    stroke: s('text-gray-600').color,
  }),
  smaller('sm')(s('pin-tr-1 w-2 h-2'))
)

const Message = styled.p(s('text-2xl'))

const Button = styled.button(
  s('w-full p-3 flex justify-center mt-4', {
    background: 'var(--primary-bayleys-color)',
    color: '#fff',
    borderRadius: 4,
    cursor: 'pointer',
  })
)

export const EmailModal = ({ leadInfo, isModalOpen, closeModal }) => {
  const [sendEmailStatus, setSendEmailStatus] = useState(ASYNC_STATUS.IDLE)

  const renderContentByStatus = (status) => {
    switch (status) {
      case ASYNC_STATUS.IDLE:
        return (
          <>
            <XCircleIcon onClick={closeModal} />
            <EmailForm leadInfo={leadInfo} setEmailSentStatus={setSendEmailStatus} />
          </>
        )

      case ASYNC_STATUS.SUCCESS:
        return (
          <>
            <Message>All done! We will contact you soon!</Message>
            <Button onClick={closeModal}>Close</Button>
          </>
        )

      case ASYNC_STATUS.PENDING:
        return <Message>Saving your details ...</Message>

      default:
        return (
          <>
            <Message>
              Something went wrong. <br />
              Please try again.
            </Message>
            <Button onClick={() => setSendEmailStatus(ASYNC_STATUS.IDLE)}>Back</Button>
          </>
        )
    }
  }

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        content: s('bg-transparent border-0', {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        }),
      }}
    >
      <Content>{renderContentByStatus(sendEmailStatus)}</Content>
    </Modal>
  )
}
