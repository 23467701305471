import React from 'react'
import { useFormikContext } from 'formik'

import { styled, s, smaller } from 'styled'
import { FREQUENCY_OPTIONS } from '../../constants'
import {
  CheckboxField,
  SelectField as SelectBase,
  TextField as TextFieldBase,
} from '../components'

import CalculatorBase from './Calculator'

const Container = styled.div(
  s('flex mx-3 p-12 flex-column', { background: '#fff' }),
  smaller('sm')(s('p-0 m-0'))
)
const Title = styled.h2(s('m-0 mt-3 ml-2 mb-3'))

const Row = styled.div(
  s('flex justify-between w-full'),
  smaller('sm')(s('flex-column'))
)

const Form = styled.div(
  s('flex flex-row m-a h-full mx-3 justify-between'),
  smaller('lg')(s('flex-column'))
)
const FormSection = styled.div(
  s('flex flex-column w-1/2', {
    '& :not(:last-child)': s('mb-1'),
    '& :first-of-type': s('mt-4'),
    '& input:first-of-type': s('mt-0'),
    width: '48%',
  }),
  smaller('lg')(s('w-full'))
)
const SectionTitle = styled.h4(s('m-0 mt-3'))
const Line = styled.div(
  s('w-full border-gray-200 border-solid', {
    borderRadius: 4,
    height: 1,
    borderWidth: 1,
  })
)

const SelectField = styled(SelectBase)(s('flex-1 h-3'))
const SelectFieldLeft = styled(SelectField)({ marginTop: 60 }, smaller('sm')(s('mt-0')))
const SelectFieldRight = styled(SelectField)(
  { marginTop: 60 },
  smaller('sm')(s('mt-0'))
)
const TextField = styled(TextFieldBase)(
  s('flex-1 mr-8'),
  smaller('sm')(s('mr-0', { marginLeft: '0px !important' }))
)

const Calculator = styled(CalculatorBase)(s('ml-4'))

const View = () => {
  const {
    values: { hasPartner },
  } = useFormikContext()

  return (
    <Container>
      <Title>Check my affordability</Title>
      <Form>
        <FormSection>
          <SectionTitle>Your income details</SectionTitle>
          <Line />
          <Row>
            <TextField
              name="clientIncome.primary.amount"
              label={'Primary salary'}
              prefix="$"
            />
            <SelectFieldLeft
              name="clientIncome.primary.frequency"
              options={FREQUENCY_OPTIONS}
            />
          </Row>
          <Row>
            <TextField
              name="clientIncome.secondary.amount"
              label={'Secondary salary'}
              prefix="$"
            />
            <SelectFieldLeft
              name="clientIncome.secondary.frequency"
              options={FREQUENCY_OPTIONS}
            />
          </Row>
          <Row>
            <TextField
              name="clientIncome.other.amount"
              label={'Other income'}
              prefix="$"
            />
            <SelectFieldLeft
              name="clientIncome.other.frequency"
              options={FREQUENCY_OPTIONS}
            />
          </Row>

          <CheckboxField
            style={s('mt-3 pr-8 py-2', { width: 'max-content' })}
            name="hasPartner"
          >
            <span>I have a partner</span>
          </CheckboxField>
          {hasPartner && (
            <>
              <Row>
                <TextField
                  name="partnerIncome.primary.amount"
                  label={'Primary salary'}
                  prefix="$"
                />
                <SelectFieldLeft
                  name="partnerIncome.primary.frequency"
                  options={FREQUENCY_OPTIONS}
                />
              </Row>
              <Row>
                <TextField
                  name="partnerIncome.secondary.amount"
                  label={'Secondary salary'}
                  prefix="$"
                />
                <SelectFieldLeft
                  name="partnerIncome.secondary.frequency"
                  options={FREQUENCY_OPTIONS}
                />
              </Row>
              <Row>
                <TextField
                  name="partnerIncome.other.amount"
                  label={'Other income'}
                  prefix="$"
                />
                <SelectFieldLeft
                  name="partnerIncome.other.frequency"
                  options={FREQUENCY_OPTIONS}
                />
              </Row>
            </>
          )}
        </FormSection>

        <FormSection>
          <SectionTitle>Your expense details</SectionTitle>
          <Line style={s('mb-6')} />
          <Row>
            <SelectFieldRight
              label="Number of dependants"
              name="dependants"
              options={[
                { value: 0, label: '0' },
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 4, label: '4' },
                { value: 5, label: '5' },
                { value: 6, label: '6' },
              ]}
            />
            <TextField
              style={s('flex-1 mt-4 ml-8 mr-0')}
              inputProps={{ style: s('w-full') }}
              name="annualExpenses"
              label={'Annual expenses'}
              prefix="$"
            />
          </Row>
          <Row>
            <TextField
              name="creditCardLimit.amount"
              label={'Credit card/s limit'}
              prefix="$"
            />
            <SelectFieldRight
              name="creditCardLimit.frequency"
              options={FREQUENCY_OPTIONS}
            />
          </Row>
          <Row>
            <TextField
              name="carLoanRepayment.amount"
              label={'Car loan repayment'}
              prefix="$"
            />
            <SelectFieldRight
              name="carLoanRepayment.frequency"
              options={FREQUENCY_OPTIONS}
            />
          </Row>
          <Row>
            <TextField name="otherPayment.amount" label={'Other payment'} prefix="$" />
            <SelectFieldRight
              name="otherPayment.frequency"
              options={FREQUENCY_OPTIONS}
            />
          </Row>
        </FormSection>
      </Form>
      <Calculator />
    </Container>
  )
}

export default View
