import React from "react";
import { Formik as FormProvider, Form as FormBase } from "formik";
import { values } from "ramda";
import * as Yup from "yup";
import { mapCustomerInfo } from "utils";
import { styled, s, smaller } from "../../styled";
import {
  CheckboxField,
  SelectField as SelectFieldBase,
  TextField as TextFieldBase,
} from "./FormFields";
import { ASYNC_STATUS, NEO_REGIONS, NEO_REGION_OPTIONS } from "../../constants";
import ky from "ky";

const { object, string, bool } = Yup;

const Form = styled(FormBase)(
  s("flex flex-column justify-between w-full"),
  smaller("lg")(s("w-full"))
);

const Button = styled.button(
  s("w-full p-3 flex justify-center mt-4", {
    background: "var(--primary-neo-color)",
    color: "#fff",
    borderRadius: 4,
    cursor: "pointer",
  })
);
const Title = styled.h2(s("m-0 mb-6 text-3xl"));
const TextField = styled(TextFieldBase)(s("mb-6"));
const SelectField = styled(SelectFieldBase)(smaller("lg")(s("mb-3 mt-1")));
const Disclaimer = styled.div(s("text-sm mt-4 mb-4"));

export const EmailForm = ({ setEmailSentStatus = () => {}, leadInfo = {} }) => {
  const location = window.location.pathname;
  const receiver = location.includes("vega") ? "vega" : "bayleys";

  return (
    <FormProvider
      initialValues={{
        name: "",
        email: "",
        phoneNumber: "",
        region: "",
        wishesToBeContacted: false,
      }}
      validationSchema={object({
        name: string().required().label("Name"),
        email: string().required().email().label("Email"),
        phoneNumber: string()
          .required()
          .label("Phone number")
          .matches(/^[0-9]{9,11}$/, "should only contain 9 - 11 digits"),
        region: string().required().oneOf(values(NEO_REGIONS)).label("Region"),
        wishesToBeContacted: bool().required(),
      })}
      onSubmit={async (customerInfo) => {
        const mappedCustomerInfo = mapCustomerInfo(customerInfo);
        const json = {
          mode: "no-cors",
          eventType: "lead_inquiry_submitted",
          payload: { customerInfo: mappedCustomerInfo, ...leadInfo, receiver },
        };

        try {
          setEmailSentStatus(ASYNC_STATUS.PENDING);
          await ky
            .post(`${process.env.REACT_APP_LEAD_INQUIRY_API_URL}`, {
              json,
            })
            .json();
          setEmailSentStatus(ASYNC_STATUS.SUCCESS);
        } catch (error) {
          setEmailSentStatus(ASYNC_STATUS.REJECTED);
        }
      }}
    >
      <Form>
        <Title>Your contact details</Title>
        <TextField name="name" label="Name" />
        <TextField name="email" label="Email" />
        <TextField
          name="phoneNumber"
          label="Phone number"
          inputProps={{ type: "number" }}
        />

        <SelectField
          name="region"
          label="Region"
          options={NEO_REGION_OPTIONS}
        />

        <CheckboxField
          style={s("mt-3 pr-6 py-2 flex items-center", {
            width: "max-content",
          })}
          name="wishesToBeContacted"
        >
          <div style={s("text-sm")}>
            I would like to be contacted by a Vega financial adviser
          </div>
        </CheckboxField>

        <Disclaimer>
          By clicking Submit, you agree to send your info to Vega Mortgages who
          agrees to use it according to their privacy policy.
          <a href="https://vegalend.co.nz/privacy-policy/">
            View Vega Mortgages’ privacy policy
          </a>
        </Disclaimer>

        <Button type="submit">Submit</Button>
      </Form>
    </FormProvider>
  );
};
