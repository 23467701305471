/* eslint-disable complexity */
import React, { useState } from "react";
import { useFormikContext } from "formik";
import { styled, s, smaller } from "styled";

import {
  calculatePeriods,
  calculateRate,
  capitalize,
  Excel,
  formatCurrency,
  tryParseFloat,
} from "utils";

import { EmailModal, ErrorIcon, ViewSwitcher } from "../components";
import { Chart } from "./Chart";
import AmortisationSchedule from "./AmortisationSchedule";
import { REPAYMENT_TYPE } from "../../constants";
import { pluck, range } from "ramda";
import { useEmailModal } from "hooks/useEmailModal";

const Container = styled.div(s("flex ml-8"), smaller("lg")(s("flex-column")));
const TopContainer = styled.div(
  s("flex-1 bg-gray-100 px-4", { borderRadius: 4 })
);
const ChartContainer = styled.div(
  s("flex px-4"),
  smaller("lg")(s("p-0 pb-4 m-0"))
);
const TableContainer = styled.div(s("px-4 h-full"));
const Summary = styled.div(
  s("flex flex-column justify-between items-center ml-8 w-16"),
  smaller("lg")(s("flex-row w-full ml-0 mt-8")),
  smaller("sm")(s("flex-column w-full ml-0 mt-8"))
);
const Button = styled.div(
  s("w-full px-2 py-2 flex justify-center", {
    background: "var(--primary-bayleys-color)",
    color: "#fff",
    borderRadius: 4,
    cursor: "pointer",
  })
);

const Calculator = (props) => {
  const {
    values: {
      loanAmount: stringLoanAmount,
      loanTerm: stringLoanTerm,
      interestRate: stringInterestRate,
      repaymentFrequency,
      repaymentType,
    },
    isValid,
    errors,
  } = useFormikContext();

  const { values: formValues } = useFormikContext();

  const [currentView, switchToView] = useState("chart");
  const { isModalOpen, openModal, closeModal } = useEmailModal();

  if (!isValid) {
    return (
      <div style={s("flex flex-column m-a text-3xl text-red-500")}>
        <h4 style={s("m-0 mb-4")}>Please correct the following errors:</h4>
        <li style={s("ml-4 text-xl", { listStyle: "none" })}>
          {Object.values(errors).map((e) => (
            <ul style={s("pl-0 flex items-center py-0 m-0 mt-2")} key={e}>
              <span>
                <ErrorIcon width={20} height={20} />
              </span>
              <p style={s("m-0 ml-2 pb-1")}>{e}</p>
            </ul>
          ))}
        </li>
      </div>
    );
  }

  const loanAmount = tryParseFloat(stringLoanAmount);
  const loanTerm = tryParseFloat(stringLoanTerm);
  const interestRate = tryParseFloat(stringInterestRate);

  const numOfPeriods = calculatePeriods(loanTerm, repaymentFrequency);
  const rate = calculateRate(interestRate, repaymentFrequency);

  let payments;
  let totalPayableInterest;
  let pmt;

  if (repaymentType === REPAYMENT_TYPE.PRINCIPAL_AND_INTEREST) {
    pmt = Excel.pmt(rate, numOfPeriods, loanAmount);
    payments = Excel.consecutivePeriods(
      rate,
      numOfPeriods,
      numOfPeriods,
      loanAmount
    );
    totalPayableInterest = Excel.totalPayableInterest(
      rate,
      numOfPeriods,
      loanAmount
    );
  } else {
    pmt = Excel.interestOnlyPmt(rate, loanAmount);
    payments = Excel.consecutivePeriodsInterestOnly(
      rate,
      numOfPeriods,
      loanAmount
    );
    totalPayableInterest = Excel.totalPayableInterestForInterestOnly(
      rate,
      numOfPeriods,
      loanAmount
    );
  }

  const paymentsToVisualise = payments.slice(1);
  const totalAmountOwed = totalPayableInterest + loanAmount;
  const principals = pluck("equity", paymentsToVisualise).map((v) => {
    // return 0 for last period principal (the actual calculation is slightly off and is below 0)
    if (v > -1 && v < 0) return 0;
    return Math.abs(v);
  });
  const totals = range(1, numOfPeriods + 1).map((period) => {
    let roundedPmt;
    if (repaymentType === REPAYMENT_TYPE.INTEREST_ONLY) {
      // Interest Only pmt does not have marginal error so can be used
      roundedPmt = pmt;
    } else {
      // must use this instead of the original principle and interest pmt to avoid marginal error causing the last total to be below 0
      roundedPmt = totalAmountOwed / numOfPeriods;
    }
    return totalAmountOwed - roundedPmt * period;
  });

  return (
    <Container {...props}>
      <TopContainer>
        <ViewSwitcher currentView={currentView} switchToView={switchToView} />
        {currentView === "chart" ? (
          <ChartContainer>
            <Chart
              totals={totals}
              principals={principals}
              numOfPeriods={numOfPeriods}
              loanTerm={loanTerm}
            />
          </ChartContainer>
        ) : (
          <TableContainer>
            <AmortisationSchedule payments={payments} />
          </TableContainer>
        )}
      </TopContainer>
      <Summary>
        <div
          style={s("flex h-16 w-full", {
            background: "var(--primary-bayleys-color)",
            color: "#fff",
            borderRadius: 4,
          })}
        >
          <p style={s("ml-12 flex flex-column justify-center mr-12")}>
            Total payable interest: <br />
            <span style={s("text-3xl font-semibold mt-2")}>{`${formatCurrency(
              totalPayableInterest
            )}`}</span>
          </p>
        </div>

        <div
          style={s("flex bg-gray-100 h-16 w-full", {
            borderRadius: 4,
          })}
        >
          <p style={s("flex flex-column justify-center ml-12")}>
            {`${capitalize(repaymentFrequency)} repayment:`}
            <br />
            <span
              style={s("text-3xl font-semibold mt-2", {
                color: "var(--primary-bayleys-color)",
              })}
            >{`${formatCurrency(Math.abs(pmt))}`}</span>
          </p>
        </div>
        <Button onClick={openModal}>Contact us</Button>
      </Summary>
      <EmailModal
        leadInfo={{
          loanRepaymentFormInfo: {
            ...formValues,
            totalPayableInterest,
            pmt,
          },
        }}
        key={isModalOpen}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </Container>
  );
};

export default Calculator;
