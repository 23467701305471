/* eslint-disable complexity */
import React from 'react'
import { Bar } from 'react-chartjs-2'
import { formatCurrency, rangeStep } from 'utils'
import debounceRender from 'react-debounce-render'

export const Chart = debounceRender(
  ({ numOfPeriods, loanTerm, totals, principals }) => {
    const interests = totals.map((t, idx) => t - principals[idx])

    const data = {
      labels: rangeStep(1, 1, numOfPeriods + 1),
      datasets: [
        {
          label: 'Principal',
          backgroundColor: '#86347D',
          data: principals,
          borderSkipped: true,
          barPercentage: 1.0,
          categoryPercentage: 1.0,
        },
        {
          label: 'Total',
          // the label and tooltips will display totals, which is the sum of the remaining principal and interest in a given period
          data: interests,
          backgroundColor: '#971b4f',
          borderSkipped: true,
          barPercentage: 1.0,
          categoryPercentage: 1.0,
        },
      ],
    }

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          reverse: true,
          position: 'bottom',
          align: 'start',
          labels: {
            usePointStyle: true,
            font: { size: 20 },
          },
        },
        tooltip: {
          backgroundColor: '#fff',
          titleColor: '#474a4c',
          titleFont: { size: 16 },
          titleMarginBottom: 16,
          bodyColor: '#474a4c',
          bodyFont: { size: 14 },
          bodySpacing: 10,
          padding: 12,
          usePointStyle: true,
          // without this principal would appear above since it's drawn first
          itemSort(a, b) {
            return b.datasetIndex - a.datasetIndex
          },
          callbacks: {
            title(context) {
              return `Period ${context[0].label}`
            },
            label(context) {
              const {
                dataset: { label },
                parsed: { y: maybePrincipal },
                dataIndex,
              } = context

              // get the actual Total for the tooltip as in reality we stack interest on top of principal
              const value = label === 'Total' ? totals[dataIndex] : maybePrincipal

              return `${label}: ${formatCurrency(value)}`
            },
          },
        },
      },
      scales: {
        y: {
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback(amount) {
              return amount !== 0 ? `$${Math.round(amount / 1_000)}k` : undefined
            },
          },
          title: {
            display: true,
            text: 'Amount owing ($)',
            color: '#474a4c',
            font: {
              family: 'Gill Sans',
              size: 24,
              style: 'normal',
            },
          },
        },
        x: {
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback(periodIndex) {
              if (loanTerm === 1) return periodIndex + 1

              let gap
              switch (true) {
                case loanTerm > 1 && loanTerm <= 5:
                  gap = 1
                  break
                case loanTerm <= 15:
                  gap = 2
                  break
                default:
                  gap = 5
                  break
              }

              const period = periodIndex + 1
              const periodsPerYear = numOfPeriods / loanTerm
              const periodEqualsFullYears = period % periodsPerYear === 0
              const yearToDisplay = period / periodsPerYear
              const yearDivisibleByGap = yearToDisplay % gap === 0

              if (periodIndex === 0) return 0

              return periodEqualsFullYears && yearDivisibleByGap
                ? yearToDisplay
                : undefined
            },
          },
          title: {
            display: true,
            text: loanTerm > 1 ? 'Years' : 'Periods',
            color: '#474a4c',
            font: {
              family: 'Gill Sans',
              size: 24,
              style: 'normal',
            },
          },
        },
      },
    }
    return (
      <div style={{ width: '100%', height: 450 }}>
        <Bar data={data} options={options} />
      </div>
    )
  },
  500
)
