const LOAN_AMOUNT_CONFIG = {
  STEP: 10_000,
  MAX: 10_000_000,
  MIN: 10_000,
  DEFAULT: 500_000,
}
const LOAN_TERM_CONFIG = {
  STEP: 1,
  MAX: 30,
  MIN: 1,
  DEFAULT: 30,
}
const INTEREST_RATE_CONFIG = {
  STEP: 0.25,
  MAX: 15.25,
  MIN: 0.25,
  DEFAULT: 6.45,
}
const REPAYMENT_TYPE = {
  PRINCIPAL_AND_INTEREST: 'principalAndInterest',
  INTEREST_ONLY: 'interestOnly',
}

const REPAYMENT_TYPE_OPTIONS = [
  { value: REPAYMENT_TYPE.PRINCIPAL_AND_INTEREST, label: 'Principal and interest' },
  { value: REPAYMENT_TYPE.INTEREST_ONLY, label: 'Interest only' },
]

const REPAYMENT_FREQUENCY = {
  MONTHLY: 'monthly',
  FORTNIGHTLY: 'fortnightly',
  WEEKLY: 'weekly',
  ANNUAL: 'annual',
}

const REPAYMENT_FREQUENCY_OPTIONS = [
  { value: REPAYMENT_FREQUENCY.MONTHLY, label: 'Monthly' },
  { value: REPAYMENT_FREQUENCY.FORTNIGHTLY, label: 'Fortnightly' },
  { value: REPAYMENT_FREQUENCY.WEEKLY, label: 'Weekly' },
]

const FREQUENCY_OPTIONS = [
  { value: REPAYMENT_FREQUENCY.MONTHLY, label: 'Monthly' },
  { value: REPAYMENT_FREQUENCY.FORTNIGHTLY, label: 'Fortnightly' },
  { value: REPAYMENT_FREQUENCY.WEEKLY, label: 'Weekly' },
  { value: REPAYMENT_FREQUENCY.ANNUAL, label: 'Annual' },
]

const NEO_REGIONS = {
  NORTHLAND: 'northland',
  AUCKLAND_NORTH: 'aucklandNorth',
  AUCKLAND_SOUTH: 'aucklandSouth',
  CENTRAL_NORTH_ISLAND: 'centralNorthIsland',
  BAY_OF_PLENTY: 'bayOfPlenty',
  LOWER_NORTH_ISLAND: 'lowerNorthIsland',
  UPPER_SOUTH_ISLAND: 'upperSouthIsland',
  MIDDLE_SOUTH_ISLAND: 'middleSouthIsland',
  LOWER_SOUTH_ISLAND: 'lowerSouthIsland',
}

const NEO_REGION_OPTIONS = [
  { value: NEO_REGIONS.NORTHLAND, label: 'Northland' },
  { value: NEO_REGIONS.AUCKLAND_NORTH, label: 'Auckland North' },
  { value: NEO_REGIONS.AUCKLAND_SOUTH, label: 'Auckland South' },
  { value: NEO_REGIONS.CENTRAL_NORTH_ISLAND, label: 'Central North Island' },
  { value: NEO_REGIONS.BAY_OF_PLENTY, label: 'Bay of Plenty' },
  { value: NEO_REGIONS.LOWER_NORTH_ISLAND, label: 'Lower North Island' },
  { value: NEO_REGIONS.UPPER_SOUTH_ISLAND, label: 'Upper South Island' },
  { value: NEO_REGIONS.MIDDLE_SOUTH_ISLAND, label: 'Middle South Island' },
  { value: NEO_REGIONS.LOWER_SOUTH_ISLAND, label: 'Lower South Island' },
]

const BAYLEYS_REGIONS = {
  AKAROA: 'akaroa',
  ARROWTOWN: 'arrowtown',
  ASHBURTON: 'ashburton',
  AUCKLAND_CENTRAL: 'aucklandCentral',
  AUCKLAND_CITY_LIVING: 'aucklandCityLiving',
  BAY_OF_ISLANDS: 'bayOfIslands',
  BAYLEY_CORPORATION: 'bayleyCorporation',
  BAYLEYS_REALTY_GROUP: 'bayleysRealtyGroup',
  BOTANY: 'botany',
  BREAM_BAY: 'breamBay',
  CAMBRIDGE: 'cambridge',
  CHRISTCHURCH: 'christchurch',
  COATESVILLE: 'coatesville',
  CROMWELL: 'cromwell',
  DARFIELD: 'darfield',
  DARGAVILLE: 'dargaville',
  DEVONPORT: 'devonport',
  DUNEDIN_HIGHGATE: 'dunedinHighgate',
  DUNEDIN_MOSGIEL: 'dunedinMosgiel',
  ELLERSLIE: 'ellerslie',
  FEILDING: 'feidling',
  FENDALTON: 'fendalton',
  FERRYMEAD: 'ferrymead',
  FIJI: 'fiji',
  FRANKTON: 'frankton',
  GISBORNE: 'gisborne',
  GORE: 'gore',
  HAMILTON: 'hamilton',
  HANMER_SPRINGS: 'hanmerSprings',
  HAVELOCK_NORTH: 'havelockNorth',
  HAWERA: 'hawera',
  HOWICK: 'howick',
  KAIKOURA: 'kaikoura',
  KAPITI: 'kapiti',
  KAPITI_BRE: 'kapitiBRE',
  KUMEU: 'kumeu',
  LONG_BAY: 'longBay',
  MAIRANGI_BAY: 'mairangiBay',
  MANGAWHAI: 'mangawhai',
  MARLBOROUGH: 'marlborough',
  MATAKANA: 'matakana',
  MATAMATA: 'matamata',
  MERIVALE: 'merivale',
  METHVEN: 'methven',
  MILLWATER: 'millwater',
  MOTUEKA: 'motueka',
  MT_MAUNGANUI: 'mtMaunganui',
  NAPIER: 'napier',
  NELSON: 'nelson',
  NORTH_SHORE_COMMERCIAL: 'northShoreCommercial',
  NORTHWEST: 'northwest',
  OMAHA_BEACH: 'omahaBeach',
  ONE_TREE_HILL: 'oneTreeHill',
  OREWA: 'orewa',
  PALMERSTON_NORTH: 'palmerstonNorth',
  PAPAKURA: 'papakura',
  PONSONBY: 'ponsonby',
  PRESTONS: 'prestons',
  PROPERTY_SERVICES_AUCKLAND: 'propertyServicesAuckland',
  PROPERTY_SERVICES_CHRISTCHURCH: 'propertyServicesChristchurch',
  PROPERTY_SERVICES_HAMILTON: 'propertyServicesHamilton',
  PROPERTY_SERVICES_TAURANGA: 'propertyServicesTauranga',
  PROPERTY_SERVICES_WELLINGTON: 'propertyServicesWellington',
  PUKEKOHE: 'pukekohe',
  QUEENSTOWN: 'queenstown',
  RANGIORA: 'rangiora',
  REMUERA: 'remuera',
  RICHMOND: 'richmond',
  ROLLSTON: 'rollston',
  ROTORUA: 'rotorua',
  RUAPEHU: 'ruapehu',
  SOUTH_AUCKLAND: 'southAuckland',
  SOUTHLAND: 'southland',
  SOUTHLAND_RURAL: 'southlandRural',
  ST_HELIERS: 'stHeliers',
  TAKAPUNA_RESIDENTIAL: 'takapunaResidential',
  TARANAKI: 'taranaki',
  TAUPO: 'taupo',
  TAURANGA: 'tauranga',
  THAMES: 'thames',
  TIMARU: 'timaru',
  TURANGI: 'turangi',
  UPPER_HUTT: 'upperHutt',
  VALUATIONS_AUCKLAND: 'valuationsAuckland',
  VALUATIONS_CHRISTCHURCH: 'valuationsChristchurch',
  VALUATIONS_HAMILTON: 'valuationsHamilton',
  VALUATIONS_WELLINGTON: 'valuationsWellington',
  WAIHEKE_AND_GREAT_BARRIER: 'waihekeAndGreatBarrier',
  WAIPUKURAU: 'waipukurau',
  WAIRARAPA: 'wairarapa',
  WANAKA: 'wanaka',
  WARKWORTH: 'warkworth',
  WELLINGTON_C_AND_I: 'wellingtonCAndI',
  WELLINGTON_RESIDENTIAL: 'wellingtonResidential',
  WELLINGTON_RESIDENTIAL_BRE: 'wellingtonResidentialBre',
  WHAKATANE: 'whakatane',
  WHANGANUI: 'whanganui',
  WHANGAREI: 'whangarei',
  WHITIANGA: 'whitianga',
}

const BAYLEYS_REGION_OPTIONS = [
  { value: BAYLEYS_REGIONS.AKAROA, label: 'Akaroa' },
  { value: BAYLEYS_REGIONS.ARROWTOWN, label: 'Arrowtown' },
  { value: BAYLEYS_REGIONS.ASHBURTON, label: 'Ashburton' },
  { value: BAYLEYS_REGIONS.AUCKLAND_CENTRAL, label: 'Auckland Central' },
  { value: BAYLEYS_REGIONS.AUCKLAND_CITY_LIVING, label: 'Auckland City Living' },
  { value: BAYLEYS_REGIONS.BAY_OF_ISLANDS, label: 'Bay of Islands' },
  { value: BAYLEYS_REGIONS.BAYLEY_CORPORATION, label: 'Bayley Corporation' },
  { value: BAYLEYS_REGIONS.BAYLEYS_REALTY_GROUP, label: 'Bayleys Realty Group' },
  { value: BAYLEYS_REGIONS.BOTANY, label: 'Botany' },
  { value: BAYLEYS_REGIONS.BREAM_BAY, label: 'Bream Bay' },
  { value: BAYLEYS_REGIONS.CAMBRIDGE, label: 'Cambridge' },
  { value: BAYLEYS_REGIONS.CHRISTCHURCH, label: 'Christchurch' },
  { value: BAYLEYS_REGIONS.CROMWELL, label: 'Cromwell' },
  { value: BAYLEYS_REGIONS.DARFIELD, label: 'Darfield' },
  { value: BAYLEYS_REGIONS.DARGAVILLE, label: 'Dargaville' },
  { value: BAYLEYS_REGIONS.DEVONPORT, label: 'Devonport' },
  { value: BAYLEYS_REGIONS.DUNEDIN_HIGHGATE, label: 'Dunedin Highgate' },
  { value: BAYLEYS_REGIONS.DUNEDIN_MOSGIEL, label: 'Dunedin Mosgiel' },
  { value: BAYLEYS_REGIONS.ELLERSLIE, label: 'Ellerslie' },
  { value: BAYLEYS_REGIONS.FEILDING, label: 'Feilding' },
  { value: BAYLEYS_REGIONS.FENDALTON, label: 'Fendalton' },
  { value: BAYLEYS_REGIONS.FERRYMEAD, label: 'Ferrymead' },
  { value: BAYLEYS_REGIONS.FIJI, label: 'Fiji' },
  { value: BAYLEYS_REGIONS.FRANKTON, label: 'Frankton' },
  { value: BAYLEYS_REGIONS.GISBORNE, label: 'Gisborne' },
  { value: BAYLEYS_REGIONS.GORE, label: 'Gore' },
  { value: BAYLEYS_REGIONS.HAMILTON, label: 'Hamilton' },
  { value: BAYLEYS_REGIONS.HANMER_SPRINGS, label: 'Hanmer Springs' },
  { value: BAYLEYS_REGIONS.HAVELOCK_NORTH, label: 'Havelock North' },
  { value: BAYLEYS_REGIONS.HAWERA, label: 'Hawera' },
  { value: BAYLEYS_REGIONS.HOWICK, label: 'Howick' },
  { value: BAYLEYS_REGIONS.KAIKOURA, label: 'Kaikoura' },
  { value: BAYLEYS_REGIONS.KAPITI, label: 'Kapiti' },
  { value: BAYLEYS_REGIONS.KAPITI_BRE, label: 'Kapiti BRE' },
  { value: BAYLEYS_REGIONS.KUMEU, label: 'Kumeu' },
  { value: BAYLEYS_REGIONS.LONG_BAY, label: 'Long Bay' },
  { value: BAYLEYS_REGIONS.MAIRANGI_BAY, label: 'Mairangi Bay' },
  { value: BAYLEYS_REGIONS.MANGAWHAI, label: 'Mangawhai' },
  { value: BAYLEYS_REGIONS.MARLBOROUGH, label: 'Malborough' },
  { value: BAYLEYS_REGIONS.MATAKANA, label: 'Matakana' },
  { value: BAYLEYS_REGIONS.MATAMATA, label: 'Matamata' },
  { value: BAYLEYS_REGIONS.MERIVALE, label: 'Merivale' },
  { value: BAYLEYS_REGIONS.METHVEN, label: 'Methven' },
  { value: BAYLEYS_REGIONS.MILLWATER, label: 'Millwater' },
  { value: BAYLEYS_REGIONS.MOTUEKA, label: 'Motueka' },
  { value: BAYLEYS_REGIONS.MT_MAUNGANUI, label: 'Mt Maunganui' },
  { value: BAYLEYS_REGIONS.NAPIER, label: 'Napier' },
  { value: BAYLEYS_REGIONS.NELSON, label: 'Nelson' },
  { value: BAYLEYS_REGIONS.NORTH_SHORE_COMMERCIAL, label: 'North Shore Commercial' },
  { value: BAYLEYS_REGIONS.NORTHWEST, label: 'Northwest' },
  { value: BAYLEYS_REGIONS.OMAHA_BEACH, label: 'Omaha Beach' },
  { value: BAYLEYS_REGIONS.ONE_TREE_HILL, label: 'One Tree Hill' },
  { value: BAYLEYS_REGIONS.OREWA, label: 'Orewa' },
  { value: BAYLEYS_REGIONS.PALMERSTON_NORTH, label: 'Palmerston North' },
  { value: BAYLEYS_REGIONS.PAPAKURA, label: 'Papakura' },
  { value: BAYLEYS_REGIONS.PONSONBY, label: 'Ponsonby' },
  { value: BAYLEYS_REGIONS.PRESTONS, label: 'Prestons' },
  {
    value: BAYLEYS_REGIONS.PROPERTY_SERVICES_AUCKLAND,
    label: 'Property Services - Auckland',
  },
  {
    value: BAYLEYS_REGIONS.PROPERTY_SERVICES_CHRISTCHURCH,
    label: 'Property Services - Christchurch',
  },
  {
    value: BAYLEYS_REGIONS.PROPERTY_SERVICES_HAMILTON,
    label: 'Property Services - Hamilton',
  },
  {
    value: BAYLEYS_REGIONS.PROPERTY_SERVICES_TAURANGA,
    label: 'Property Services - Tauranga',
  },
  {
    value: BAYLEYS_REGIONS.PROPERTY_SERVICES_WELLINGTON,
    label: 'Property Services - Wellington',
  },
  { value: BAYLEYS_REGIONS.PUKEKOHE, label: 'Pukekohe' },
  { value: BAYLEYS_REGIONS.QUEENSTOWN, label: 'Queenstown' },
  { value: BAYLEYS_REGIONS.RANGIORA, label: 'Rangiora' },
  { value: BAYLEYS_REGIONS.REMUERA, label: 'Remuera' },
  { value: BAYLEYS_REGIONS.RICHMOND, label: 'Richmond' },
  { value: BAYLEYS_REGIONS.ROLLSTON, label: 'Rollston' },
  { value: BAYLEYS_REGIONS.ROTORUA, label: 'Rotorua' },
  { value: BAYLEYS_REGIONS.RUAPEHU, label: 'Ruapehu' },
  { value: BAYLEYS_REGIONS.SOUTH_AUCKLAND, label: 'South Auckland' },
  { value: BAYLEYS_REGIONS.SOUTHLAND, label: 'Southland' },
  { value: BAYLEYS_REGIONS.SOUTHLAND_RURAL, label: 'Southland Rural' },
  { value: BAYLEYS_REGIONS.ST_HELIERS, label: 'St Heliers' },
  { value: BAYLEYS_REGIONS.TAKAPUNA_RESIDENTIAL, label: 'Takapuna Residential' },
  { value: BAYLEYS_REGIONS.TARANAKI, label: 'Taranaki' },
  { value: BAYLEYS_REGIONS.TAUPO, label: 'Taupo' },
  { value: BAYLEYS_REGIONS.TAURANGA, label: 'Tauranga' },
  { value: BAYLEYS_REGIONS.THAMES, label: 'Thames' },
  { value: BAYLEYS_REGIONS.TIMARU, label: 'Timaru' },
  { value: BAYLEYS_REGIONS.TURANGI, label: 'Turangi' },
  { value: BAYLEYS_REGIONS.UPPER_HUTT, label: 'Upper Hutt' },
  { value: BAYLEYS_REGIONS.VALUATIONS_AUCKLAND, label: 'Valuations - Auckland' },
  {
    value: BAYLEYS_REGIONS.VALUATIONS_CHRISTCHURCH,
    label: 'Valuations - Christchurch',
  },
  { value: BAYLEYS_REGIONS.VALUATIONS_HAMILTON, label: 'Valuations - Hamilton' },
  { value: BAYLEYS_REGIONS.VALUATIONS_WELLINGTON, label: 'Valuations - Wellington' },
  {
    value: BAYLEYS_REGIONS.WAIHEKE_AND_GREAT_BARRIER,
    label: 'Waiheke and Great Barrier',
  },
  { value: BAYLEYS_REGIONS.WAIPUKURAU, label: 'Waipukurau' },
  { value: BAYLEYS_REGIONS.WAIRARAPA, label: 'Wairarapa' },
  { value: BAYLEYS_REGIONS.WANAKA, label: 'Wanaka' },
  { value: BAYLEYS_REGIONS.WARKWORTH, label: 'Warkworth' },
  { value: BAYLEYS_REGIONS.WELLINGTON_C_AND_I, label: 'Wellington C & I' },
  { value: BAYLEYS_REGIONS.WELLINGTON_RESIDENTIAL, label: 'Wellington Residential' },
  {
    value: BAYLEYS_REGIONS.WELLINGTON_RESIDENTIAL_BRE,
    label: 'Wellington Residential BRE',
  },
  { value: BAYLEYS_REGIONS.WHAKATANE, label: 'Whakatane' },
  { value: BAYLEYS_REGIONS.WHANGANUI, label: 'Whanganui' },
  { value: BAYLEYS_REGIONS.WHANGAREI, label: 'Whangarei' },
  { value: BAYLEYS_REGIONS.WHITIANGA, label: 'Whitianga' },
]

export {
  LOAN_AMOUNT_CONFIG,
  LOAN_TERM_CONFIG,
  INTEREST_RATE_CONFIG,
  REPAYMENT_TYPE_OPTIONS,
  REPAYMENT_FREQUENCY_OPTIONS,
  REPAYMENT_TYPE,
  REPAYMENT_FREQUENCY,
  FREQUENCY_OPTIONS,
  NEO_REGIONS,
  NEO_REGION_OPTIONS,
  BAYLEYS_REGIONS,
  BAYLEYS_REGION_OPTIONS,
}

export const ASYNC_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  REJECTED: 'rejected',
}
