import React, { useMemo } from 'react'

import { Table } from '../components'
import { formatCurrency } from 'utils'

const Columns = () => {
  return useMemo(
    () => [
      {
        accessor: 'period',
        Header: 'Period',
      },
      {
        accessor: 'payment',
        Header: 'Payment',
      },
      {
        accessor: 'principal',
        Header: 'Principal',
      },
      {
        accessor: 'interest',
        Header: 'Interest',
      },
      {
        accessor: 'equity',
        Header: 'Equity',
      },
    ],
    []
  )
}

const Rows = (payments = []) =>
  useMemo(
    () =>
      payments.map((p) => ({
        period: p.period,
        payment: formatCurrency(p.payment),
        interest: formatCurrency(p.interest),
        principal: formatCurrency(p.principal),
        equity: formatCurrency(p.equity),
      })),
    [payments]
  )

const AmortisationSchedule = ({ payments = [], ...tableProps }) => {
  const columns = Columns()
  const data = Rows(payments)

  return <Table columns={columns} data={data} {...tableProps} />
}

export default AmortisationSchedule
