/* eslint-disable complexity */
/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useField } from 'formik'
import { styled, s } from '../../styled'
import { ErrorIcon } from './ErrorIcon'

const FieldLabel = styled.label(s('text-xl'))

const Input = styled.input(s('border-0 text-xl flex-1', { outline: 'none' }))

const InputContainer = styled.div(
  s('border-solid border-gray-300 rounded-md h-3 flex items-center px-4', {
    borderWidth: 2,
  }),
  ({ error }) => error && s('border-red-400')
)

const StandAloneInput = styled.input(
  s('pl-4 border-solid border-gray-300 rounded-md h-3 text-xl'),
  ({ error }) => error && s('border-red-400')
)

export const TextField = ({
  label,
  id,
  name,
  inputProps,
  containerProps,
  prefix,
  suffix,
  ...props
}) => {
  const [field, meta] = useField(name)

  return (
    <div style={s('flex flex-column')} {...containerProps} {...props}>
      <FieldLabel htmlFor={id || name}>{label}</FieldLabel>
      {prefix || suffix ? (
        <InputContainer error={meta.error && meta.touched}>
          <span style={s('m-0')}>{prefix}</span>
          <Input {...field} {...inputProps} />
          {suffix && <span style={s('m-0')}>{suffix}</span>}
        </InputContainer>
      ) : (
        <StandAloneInput {...field} {...inputProps} error={meta.error} />
      )}
      {meta.error ? (
        <ErrorMessage error={meta.error} errorProps={{ style: s('ml-1 mt-4') }} />
      ) : null}
    </div>
  )
}

const CheckboxInput = styled.input(
  s('border-solid border-gray-400 rounded-md w-2 h-2 mr-4', {
    color: 'var(--primary-bayleys-color)',
    outline: 'none',
  })
)
export const CheckboxField = ({ children, name, inputProps, ...props }) => {
  const [field] = useField({ name, type: 'checkbox' })
  return (
    <FieldLabel {...props}>
      <CheckboxInput {...field} {...inputProps} type="checkbox" />
      {children}
    </FieldLabel>
  )
}
const Select = styled.select(
  s('pl-4 border-solid border-gray-300 rounded-md w-full text-xl h-3', {
    background: '#fff',
    color: 'var(--text-color)',
    borderWidth: 2,
  })
)

const ErrorMessage = ({ error, errorProps }) => {
  return (
    <div style={s('text-red-600 flex items-center')}>
      <ErrorIcon width={20} height={20} />
      <span {...errorProps}>{error}</span>
    </div>
  )
}

export const SelectField = ({ label: fieldLabel, id, name, options, ...props }) => {
  const [field, meta] = useField(name)
  return (
    <div {...props}>
      <FieldLabel htmlFor={id || name}>{fieldLabel}</FieldLabel>
      <Select {...field} {...props}>
        {options.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Select>
      {meta.error ? <ErrorMessage error={meta.error} /> : null}
    </div>
  )
}

const StandAloneSliderTextInput = styled.input(
  s('pl-4 my-0 border-solid border-gray-300 rounded-md h-3 text-xl flex-1', {
    borderWidth: 2,
    outline: 'none',
  }),
  ({ error }) => error && s('border-red-400')
)

const Slider = styled.input(
  s('w-full bg-gray-300 mt-3', {
    appearance: 'none',
    outline: 'none',
    height: 4,
    borderRadius: 8,
    '&::-webkit-slider-thumb': {
      appearance: 'none',
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      height: 16,
      width: 16,
      borderRadius: '50%',
      background: 'var(--primary-bayleys-color)',
    },
    '&::-moz-range-thumb': {
      appearance: 'none',
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      height: 16,
      width: 16,
      borderRadius: '50%',
      background: 'var(--primary-bayleys-color)',
    },
    '&::-ms-thumb': {
      appearance: 'none',
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      height: 16,
      width: 16,
      borderRadius: '50%',
      background: 'var(--primary-bayleys-color)',
    },
  })
)
export const RangeSlider = ({
  label,
  id,
  name,
  labelProps,
  containerProps,
  withTextInput = true,
  prefix,
  suffix,
  ...props
}) => {
  const [field, meta] = useField(name)
  return (
    <div {...containerProps}>
      <FieldLabel htmlFor={id || name} {...labelProps}>
        {label}
      </FieldLabel>
      {withTextInput &&
        (prefix || suffix ? (
          <InputContainer>
            <span style={s('m-0')}>{prefix}</span>
            <Input {...field} />
            {suffix && <span style={s('m-0')}>{suffix}</span>}
          </InputContainer>
        ) : (
          <div style={s('w-full flex')}>
            <StandAloneSliderTextInput {...field} error={meta.error} />
          </div>
        ))}
      <Slider type="range" {...field} {...props} />
      {meta.error ? <ErrorMessage error={meta.error} /> : null}
    </div>
  )
}
